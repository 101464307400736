<template>
  <div></div>
</template>

<script>
import * as L from "leaflet";
import geoMejoras from '../mixins/geoMejoras';

export default {
  name: "GeoCaminos",
  mixins: [geoMejoras],
  data() {
    return {
      typeName: this.ModelTypes.Caminos.name,
      colName: this.ModelTypes.Caminos.field,
      styleSizeFactor: 1,
      radius: 10,
      normalIcon: L.icon({
        iconUrl: require('@/assets/SCOtrosElementos.svg'),
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        tooltipAnchor: [16, -28],
        shadowSize: [41, 41]
      }),
      selectedIcon: L.icon({
        iconUrl: require('@/assets/Seleccion_otrosElementos.svg'),
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        tooltipAnchor: [16, -28],
        shadowSize: [41, 41]
      })
      /*
      normalStyle: {
        color: "red",
        weight: 2
      },
      selectedStyle: {
        color: "yellow",
        weight: 2
      }
      */
    };
  },
  methods: {
    createLayer: function(geom){
      return L.polyline(geom, { 
        showMeasurements: true, 
        measurementOptions: { 
          formatDistance: this.distanceInMeters
        }  
      });
    },
    getVertexs: function(geom){
      let res = [];

      geom._latlngs.forEach(element => {
        res.push([element.lat, element.lng]);
      });

      return res;
    }
  }
};
</script>
