<template>
  <v-card color="grey lighten-4" flat height="50vh" tile>
    <v-toolbar id="toolbar" flat dense>
      <v-switch v-model="isEdition" hint="Editar?" persistent-hint @change="edit"></v-switch>
      <v-btn :disabled="selLength === 0 || (isEdition && type !== null)" icon>
        <v-icon color="blue" @click.stop="dialog = true">mdi-table-edit</v-icon>
      </v-btn>

      <v-btn :disabled="!isEdition || (isEdition && type !== null)" icon>
        <v-icon color="blue" @click.stop="create(types[0].name)">mdi-plus</v-icon>
      </v-btn>
      <v-btn :disabled="!isEdition || (isEdition && (type !== null || selLength === 0))" icon>
        <v-icon color="blue" @click.stop="remove(types[0].name)">mdi-minus</v-icon>
      </v-btn>
      <v-btn :disabled="!isEdition || (isEdition && (type !== null || selLength === 0))" icon>
        <v-icon color="blue" @click.stop="move()">mdi-move-resize-variant</v-icon>
      </v-btn>
    </v-toolbar>
    <v-data-table
      :headers="headers"
      :items="itemsCollection"
      v-model="selected"
      item-key="id"
      @item-selected="itemSelected($event)"
      :single-select="true"
      show-select
      dense
      height="40vh"
      fixed-header
      disable-pagination
      hide-default-footer
      class="elevation-1"
    >
      <template v-slot:item="{item, isSelected, select}">
        <tr v-bind:class="{'red lighten-3': item.modified && !item.deleted}">
          <td>
            <v-checkbox v-show="!item.deleted" :value="isSelected" @change="select($event)"></v-checkbox>
            <v-icon v-show="item.deleted && item.deleted > 0">mdi-delete</v-icon>
          </td>
          <td>{{(item.tiposDeGallineroChiqueroId) ? item.tiposDeGallineroChiqueroId.nombre : null}}</td>
          <td>{{item.metrosCuadrados}}</td>
          <td>{{(item.tiposDeMaterialesGallineroId) ? item.tiposDeMaterialesGallineroId.nombre : null}}</td>
        </tr>
      </template>
    </v-data-table>
    <GallineroChiquero
      :data="data"
      :dialog="dialog&& ((selLength > 0 && selected[0].type === types[0].name) || (isEdition && type === types[0].name))"
      :edit="isEdition" :isCancelling="isCancelling"
      @commit="commit"
      @cancel="cancel"
      @close="close"
    />
    <MoverMejora :show="showFracciones" @close="closeShowFracciones"/>
  </v-card>
</template>

<script>
import "@mdi/font/css/materialdesignicons.css";
import GallineroChiquero from "./GallineroChiquero";
import mejoras from "../mixins/mejoras";

export default {
  name: "GallinerosChiqueros",
  components: { GallineroChiquero },
  mixins: [mejoras],
  data() {
    return {
      types: [
        { name: this.ModelTypes.GallinerosChiqueros.name, text: "Gallineros / Chiqueros", colName: this.ModelTypes.GallinerosChiqueros.field, geom: "Point"}
      ],
      headers: [
        { text: "Tipo", value: "tiposDeGallineroChiqueroId.nombre", sortable: false },
        { text: "Metros", value: "metrosCuadrados", sortable: false },
        { text: "Material", value: "tiposDeMaterialGallineroId.nombre", sortable: false }
      ]
    };
  }
};
</script>
