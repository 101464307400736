import { mapState } from 'vuex';
import MoverMejora from "../components/MoverMejora";

export default {
  props: ["update", "map"],
  components: { MoverMejora },
  data() {
    return {
      type: null,
      itemsCollection: [],
      dialog: false,
      selected: [],
      isEdition: false,
      isCancelling: false,
      data: null,
      showFracciones: false
    }
  },
  activated() {
    this.updateGui();
  },
  mounted() {
    this.map.on("click", this.clickMap);
    this.map.on("pm:create", this.newElement);
    if (this.types && this.types.length > 0){
      this.loadItems();
    } else {
      console.log("No hay datos para cargar!!!");
    }
  },
  methods: {
    loadItems: function(){
//      console.debug('recargando tabla...');
      this.itemsCollection.length = 0;
//      console.debug(this.itemsCollection);
      
      for (const type of this.types){
        let colName = this.getColName(type.name);
//        console.debug(colName + '...');

        if (this.wrkFraccion[colName] && this.wrkFraccion[colName].length > 0){
          this.itemsCollection = this.itemsCollection.concat(this.wrkFraccion[colName]);
        }
      }
//      console.debug(this.itemsCollection);
    },
    clickMap: function () {
      if (this.type) return;


      if (this.isEdition && this.wrkState.selected && !this.isType(this.wrkState.selected.type)) {
        this.$toasted.info("No es posible ... edicion en curso de " + this.wrkState.selected.type, {
          duration: 1500
        });
      } else {
        this.$store.dispatch('setWrkSelected', null);
      }
    },
    updateGui: function () {
      if (this.wrkState.selected && this.isType(this.wrkState.selected.type)) {
        let tmp = this.wrkFraccion[this.getColName(this.wrkState.selected.type)]
          .find(element => element.id === this.wrkState.selected.id);
          if (tmp){
            this.selected.push(tmp);
            this.data = tmp;    
          }
      }
      this.loadItems();
    },
    close: function () {
      this.dialog = false;
    },
    commit: function () {
      this.dialog = false;
//      if (this.selLength > 0) {
        let id = this.selected[0].id;
        let type = this.selected[0].type;
        let tmp = this.wrkFraccion[this.getColName(type)].find(element => element.id === id);
        tmp.modified = Date.now();
        tmp.tiposDeMejorasId = this.$setType(tmp);
        let tiposDeConstruccion = this.$setTiposDeConstruccion(tmp);
        if (tiposDeConstruccion){
          tmp.tiposDeConstruccionId = tiposDeConstruccion;
        }
//      }

      this.$store.dispatch("updateFraccion").then(() => {
        this.$store.dispatch("logFotos", "Fraccion guardada...");
        this.loadItems();
        this.$toasted.show("Cambios guardados...", {
          type: "success",
          duration: 1500
        });
      }).finally(() => {
        this.$store.dispatch('setWrkEdit', null);
        this.isEdition = false;
        this.$store.dispatch('setWrkSelected', null);
        this.$store.dispatch('setWrkRefresh', { type: [type] });
      });
    },
    cancel: function () {
      this.isCancelling = true;
      this.$toasted.show(`
        <div>
        <h3>¿Desea cancelar la edicion y perder los cambios?</h3>
        <br /><h4>Si cancela la edición perderá todos los cambios realizados.<br />También se perderán las fotos que se hayan tomado y no estén guardadas.</h4>
        </div>
        `, {
        Icon: 'mdi-alert',
        iconPack: 'mdi',
        action: [{
          text: 'Cancelar',
          onClick: (e, toastObject) => {
            toastObject.goAway(0);
            this.dialog = false;
            this.$store.dispatch("restoreFraccion").then(() => {
              this.$store.dispatch("logFotos", "Cambios cancelados...");
              this.$toasted.show("Cambios cancelados...", {
                type: "success",
                duration: 1500
              });
            })
              .finally(() => {
                this.type = null;
                this.isEdition = false;
                this.isCancelling = false;
                this.map.pm.disableDraw();
                this.$store.dispatch('setWrkRefresh', { type: this.getTypeNames });
                this.$store.dispatch('setWrkSelected', null);
                this.$store.dispatch('setWrkEdit', null);
              });      
          }
        }, {
          text: 'Continuar',
          onClick: (e, toastObject) => {
            toastObject.goAway(0);
            this.isEdition = true;
            this.isCancelling = false;
          }
        }]
      });
     },
    itemSelected: function (event) {
      this.$store.dispatch('setWrkSelected', (event.value) ? { id: event.item.id, type: event.item.type, geo: false } : null);
    },
    create: function (type) {
      this.type = type;

      if (this.getGeomType(type) === 'Line'){ 
        this.map.pm.enableDraw("Line", { finishOn: 'contextmenu', removeLayerBelowMinVertexCount: false });
      } else {
        this.map.pm.enableDraw("Marker", {preventMarkerRemoval: true});
      }    
    },
    remove: function(){
      this.$toasted.show("¿Desea eliminar la mejora seleccionada?", {
        Icon: 'mdi-minus',
        iconPack: 'mdi',
        action: {
          text: 'Aceptar',
          onClick: (e, toastObject) => {
            let element = this.itemsCollection.find(el => el.id === this.selected[0].id); 

            if (element){
              let colName = this.getColName(element.type);
              console.log('Eliminando elemento del tipo ' + colName);
              let tableIndex = this.itemsCollection.map((element) => element.id).indexOf(element.id);
              let logical = true;

              if (element.id.toString().startsWith(this.General.tmpId)){
                console.log("Eliminando " + JSON.stringify(element));
                this.itemsCollection.splice(tableIndex, 1);
                logical = false;
              } else {
                console.log("Marcando como eliminado " + JSON.stringify(element));
                this.itemsCollection[tableIndex].deleted = Date.now();
              }

              this.$store.dispatch('setMejoraDeleted', {
                idFraccion: this.$store.state.wrkFraccion.id,
                idMejora: element.id,
                field: colName,
                logical: logical
              }).finally(() => {
                this.loadItems();
                this.$store.dispatch('setWrkEdit', null);
                this.isEdition = false;
                this.$store.dispatch('setWrkSelected', null);
                this.$store.dispatch('setWrkRefresh', { type: [element.type] });
                this.$toasted.show("Cambios guardados...", {
                  type: "success",
                  duration: 1500
                });
              });
            } else {
              console.log("No encontre elemento " + this.selected[0]);
            }

            toastObject.goAway(0);
          }
        },
        duration: 4500
      });
    },
    move: function(){
      console.log('mover mejora...');
      if (this.selected[0].id.toString().startsWith(this.General.tmpId)) {
        let msg = "Mejora temporal: debe primero exportar la mejora al servidor para poder transferirla!...";
        this.$toasted.show(msg, {
          type: "info",
          duration: 5000,
        });
        console.log(msg);
      } else {
        this.showFracciones = true;
      }            
    },
    closeShowFracciones: function(){
      this.showFracciones = false;
    },
    edit: function () {
      if (this.isEdition) {
        this.$store.dispatch('setWrkEdit', { type: this.getTypeNames });
      } else {
        this.cancel();
      }
    },
    newElement: function (e) {
      if (this.type == null) return;

      this.map.pm.disableDraw();
      if (this.$store.state.wrkState.edit.type.indexOf(this.type) < 0){ 
        let error = 'evento create en otro tipo... (' + this.type + ", " + this.$store.state.wrkState.edit.type + ")";
        console.log(error);
        this.$toasted.show(error, {
          type: "error",
          duration: 3000,
        });

        return;
      }

      let element = {
        id: this.General.tmpId + new Date().getTime(),
        deleted: null,
        modified: Date.now(),
        type: this.type,
        fotosSIMCollection: [],
        propiedadINC: null,
        porcentajePropiedadDelColono: null,
        timestamp: new Date().getTime(),
        geoInfo:  (this.getGeomType(this.type) === "Line") ?
          this.getLineVertexs(e.layer) :
          this.getPointVertexs(e.layer)
      };

//      console.log('Nueva mejora: ' + JSON.stringify(element));

      if (!this.$store.state.wrkFraccion[this.getColName(this.type)]){
        console.log("Creando coleccion para tipo: " + this.getColName(this.type));
        this.$store.state.wrkFraccion[this.getColName(this.type)] = [];
      }

      this.$store.state.wrkFraccion[this.getColName(this.type)].push(element);
      this.$store.dispatch('setWrkSelected', { id: element.id, type: this.type, geo: true });
      this.map.removeLayer(e.layer);
      this.dialog = true;

      this.type = null;
    },
    getLineVertexs: function(geom){
      let res = [];

      geom._latlngs.forEach(element => {
        res.push([element.lat, element.lng]);
      });

      return res;
  },
  getPointVertexs: function(geom){
      return [geom._latlng.lat, geom._latlng.lng];
  },
  getTypeText: function(name){
    return this.types.find(el => el.name === name).text;
  },
  isType: function(name){
    return this.getTypeNames.indexOf(name) > -1; 
  },
  isAllTypes: function(names){
    return JSON.stringify(names) === JSON.stringify(this.getTypeNames);
  },
  getColName: function(typeName){
    return this.types.find(el => el.name === typeName).colName;
  },
  getGeomType: function(name){
    return this.types.find(el => el.name === name).geom;
  }
  },
  computed: {
    selLength: function () {
      return this.selected.length;
    },
    getTypeNames: function(){
      return this.types.map(e => e.name);
    },
      ...mapState(['wrkFraccion', 'wrkState'])
  },
  watch: {
    wrkState(newSel, oldSel) {
      if (oldSel.selected && this.isType(oldSel.selected.type)) {
        this.selected = [];
      }
      if (newSel.selected && this.isType(newSel.selected.type)) {
        this.selected = [];
        let tmp = this.wrkFraccion[this.getColName(newSel.selected.type)].find(element => element.id === newSel.selected.id);
        this.selected.push(tmp);
        this.data = tmp;
      }
    },
    update: function () {
      this.updateGui();
    }
  }
}