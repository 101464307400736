<template>
    <v-card width="400" class="mx-auto mt-5">
      <v-card-title class="pb-0">
        <h1>Login</h1>
      </v-card-title>
      <v-card-text>
        <v-form>
          <v-text-field 
            label="Usuario" 
            prepend-icon="mdi-account-circle"
            autocomplete="off"
            v-model="user"
          />
          <v-text-field 
            :type="showPassword ? 'text' : 'password'" 
            label="Password"
            prepend-icon="mdi-lock"
            autocomplete="off"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            v-model="pass"
            @click:append="showPassword = !showPassword"
          />
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn :loading="loading" @click="btnLogin" color="info">Login</v-btn>
      </v-card-actions>
    </v-card>
</template>

<script>
import "@mdi/font/css/materialdesignicons.css";

export default {
  name: "Login",
  data() {
    return {
      showPassword: false,
      user: null,
      pass: null,
      loading: false
    };
  },
  methods: {
    btnLogin: function(){
      this.loading = true;

      this.$ping()
      .then((res) => {
        if (res){
          console.log("ping ok");
          return this.$login(this.user, this.pass);
        } else {
          console.log("ping fallo, buscando usuario " + this.user);
          let usuario = this.$store.getters.getUsuario(this.user);
          if (!usuario){
            throw new Error("Sin conexion y usuario inexistente...");
          }
          if (this.pass != usuario.pass){
            throw new Error("Sin conexion y pass interna incorrecta...");
          }
        }
      }).then(() => {
          console.log("actualizando usuario");
          this.$store.dispatch("updateUsuario", {user: this.user, pass: this.pass, log: true});
          this.$router.push('home');
      })
      .catch((e) => {
        console.log(e);
          this.$toasted.show(e, {type: "error", duration: 5000});        
      })
      .finally(() => {
        this.loading = false;
      });
    }
  }
};
</script>
