<template>
  <v-container>
    <v-container>
    <v-row>
      <h3>Imagenes fuera de linea</h3>
    </v-row>
    <p></p>
    <v-row>
      <v-simple-table dense>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Capa</th>
              <th class="text-left">Total</th>
              <th class="text-left">Descargadas</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in images" :key="item.name">
              <td class="text-left">{{ item.name }}</td>
              <td class="text-right">{{ item.total }}</td>
              <td class="text-right">{{ item.download }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-row>
    <v-row justify="start">
      <v-btn small class="ma-2" color="primary" @click="download">Descargar</v-btn>
      <v-btn small class="ma-2" color="error" @click="remove">Eliminar</v-btn>
    </v-row>
    </v-container>
    <v-container>
    <v-row>
      <h3>Descargar imagen mapa</h3>
    </v-row>
    <p></p>
    <p></p>
    <v-row>
      <v-btn small color="primary" @click.stop="print">Descargar</v-btn>
    </v-row>
    <p></p>
    </v-container>
    <v-container>
    <v-row>
      <h3>Estilos</h3>
    </v-row>
    <v-row cols="12">
    <v-radio-group v-model="styleSize" row @change="change">
      <v-radio label="Chico" value="small" key="1"></v-radio>
      <v-radio label="Mediano" value="normal" key="2"></v-radio>
      <v-radio label="Grande" value="big" key="3"></v-radio>
    </v-radio-group>
    </v-row>
    </v-container>
  </v-container>
</template>

<script>
import * as L from "leaflet";
import "leaflet.offline";
//import { mapState } from "vuex";

import "@mdi/font/css/materialdesignicons.css";

export default {
  name: "OfflineInfo",
  components: {},
  props: ["map", "osmLayer", "googleLayer"],
  data: () => ({
    controlOSM: null,
    styleSize: "normal",
    controlGoogle: null,
    images: [ 
      { name: "OSM", total: 0, download: 0 },
      { name: "Google", total: 0, download: 0 }
    ]
  }),
  activated(){
    this.styleSize = this.$store.getters.getWrkStyleSize();
  },
  mounted() {
    this.controlOSM = L.control.savetiles(this.osmLayer, {zoomlevels: [9,10,11,12,13,14,15,16,17,18]}).addTo(this.map);
    this.controlGoogle = L.control.savetiles(this.googleLayer, {zoomlevels: [9,10,11,12,13,14,15,16,17,18]}).addTo(this.map);

    this.osmLayer.on("savestart", this.initDownloadOSM);
    this.osmLayer.on("savetileend", this.updateDownloadOSM);
    this.osmLayer.on("storagesize", this.storageSizeOSM);
    this.googleLayer.on("savestart", this.initDownloadGoogle);
    this.googleLayer.on("savetileend", this.updateDownloadGoogle);
    this.googleLayer.on("storagesize", this.storageSizeGoogle);
  },
  beforeDestroy() {
    this.osmLayer.remove();
    this.googleLayer.remove();
    this.osmLayer.off("savestart", this.initDownloadOSM);
    this.osmLayer.off("savetileend", this.updateDownloadOSM);
    this.osmLayer.off("storagesize", this.storageSizeOSM);
    this.googleLayer.off("savestart", this.initDownloadGoogle);
    this.googleLayer.off("savetileend", this.updateDownloadGoogle);
    this.googleLayer.off("storagesize", this.storageSizeGoogle);
  },
  computed:{
  },
  methods: {
    change: function(size){
        console.log('size ' + size);
        this.$store.dispatch('setWrkStyleSize', size);
    },
    download: function() {
      this.controlOSM._saveTiles();
      this.controlGoogle._saveTiles();
    },
    remove: function() {
      this.controlOSM._rmTiles();
      this.controlGoogle._rmTiles();
    },
    initDownloadOSM: function(e) {
      this.images[0].download = 0;
      this.images[0].total = e._tilesforSave.length;
    },
    updateDownloadOSM: function() {
      this.images[0].download += 1;
    },
    initDownloadGoogle: function(e) {
      this.images[1].download = 0;
      this.images[1].total = e._tilesforSave.length;
    },
    updateDownloadGoogle: function() {
      this.images[1].download += 1;
    },
    storageSizeOSM(e) {
      this.images[0].total = e.storagesize;
      this.images[0].download = e.storagesize;
    },
    storageSizeGoogle(e) {
      this.images[1].total = e.storagesize;
      this.images[1].download = e.storagesize;
    },
    print: function() {
      this.$emit("printMap");
    }
  }
};
</script>
