export default {
  methods: {
    checkMejorasFraccion: function(fraccion){
      let res = []; // array con los errores de validacion

      this.checkFraccionesWrapper(res, fraccion);

      if (fraccion[this.ModelTypes.Viviendas.field]){
        for (let mejora of fraccion[this.ModelTypes.Viviendas.field]){
          if (this.checkTemporal(mejora)) continue;

          this.checkGeneral(res, mejora);
          this.checkGeneralConstrucciones(res, mejora);
          this.checkViviendas(res, mejora);
        }
      }
      if (fraccion[this.ModelTypes.Alambrados.field]){
        for (let mejora of fraccion[this.ModelTypes.Alambrados.field]){
          if (this.checkTemporal(mejora)) continue;
          this.checkGeneral(res, mejora);
          this.checkAlambrados(res, mejora);
        }
      }
      if (fraccion[this.ModelTypes.Galpones.field]){
        for (let mejora of fraccion[this.ModelTypes.Galpones.field]){
          if (this.checkTemporal(mejora)) continue;
          this.checkGeneral(res, mejora);
          this.checkGeneralConstrucciones(res, mejora);
          this.checkGalpones(res, mejora);
        }
      }
      if (fraccion[this.ModelTypes.SalasOrdene.field]){
        for (let mejora of fraccion[this.ModelTypes.SalasOrdene.field]){
          if (this.checkTemporal(mejora)) continue;
          this.checkGeneral(res, mejora);
          this.checkGeneralConstrucciones(res, mejora);
          this.checkSalasOrdene(res, mejora);
        }
      }
      if (fraccion[this.ModelTypes.GuardaPatios.field]){
        for (let mejora of fraccion[this.ModelTypes.GuardaPatios.field]){
          if (this.checkTemporal(mejora)) continue;
          this.checkGeneral(res, mejora);
          this.checkGuardaPatios(res, mejora);
        }
      }
      if (fraccion[this.ModelTypes.Decantadores.field]){
        for (let mejora of fraccion[this.ModelTypes.Decantadores.field]){
          if (this.checkTemporal(mejora)) continue;
          this.checkGeneral(res, mejora);
          this.checkDecantadores(res, mejora);
        }
      }
      if (fraccion[this.ModelTypes.SistemasRiego.field]){
        for (let mejora of fraccion[this.ModelTypes.SistemasRiego.field]){
          if (this.checkTemporal(mejora)) continue;
          this.checkGeneral(res, mejora);
          this.checkRiego(res, mejora);
        }
      }
      if (fraccion[this.ModelTypes.Caminos.field]){
        for (let mejora of fraccion[this.ModelTypes.Caminos.field]){
          if (this.checkTemporal(mejora)) continue;
          this.checkGeneral(res, mejora);
          this.checkMejorasConEstado(res, mejora);
          this.checkCaminos(res, mejora);
        }
      }
      if (fraccion[this.ModelTypes.Pozos.field]){
        for (let mejora of fraccion[this.ModelTypes.Pozos.field]){
          if (this.checkTemporal(mejora)) continue;
          this.checkGeneral(res, mejora);
          this.checkMejorasConEstado(res, mejora);
          this.checkPozos(res, mejora);
        }
      }
      if (fraccion[this.ModelTypes.PozosBalde.field]){
        for (let mejora of fraccion[this.ModelTypes.PozosBalde.field]){
          if (this.checkTemporal(mejora)) continue;
          this.checkGeneral(res, mejora);
          this.checkMejorasConEstado(res, mejora);
          this.checkPozos(res, mejora);
          this.checkPozosBalde(res, mejora);
        }
      }
      if (fraccion[this.ModelTypes.Molinos.field]){
        for (let mejora of fraccion[this.ModelTypes.Molinos.field]){
          if (this.checkTemporal(mejora)) continue;
          this.checkGeneral(res, mejora);
          this.checkMejorasConEstado(res, mejora);
          this.checkMolinos(res, mejora);
        }
      }
      if (fraccion[this.ModelTypes.Bombas.field]){
        for (let mejora of fraccion[this.ModelTypes.Bombas.field]){
          if (this.checkTemporal(mejora)) continue;
          this.checkGeneral(res, mejora);
          this.checkMejorasConEstado(res, mejora);
          this.checkBombas(res, mejora);
        }
      }
      if (fraccion[this.ModelTypes.BebederosComederos.field]){
        for (let mejora of fraccion[this.ModelTypes.BebederosComederos.field]){
          if (this.checkTemporal(mejora)) continue;
          this.checkGeneral(res, mejora);
          this.checkMejorasConEstado(res, mejora);
          this.checkBebederosComederos(res, mejora);
        }
      }
      if (fraccion[this.ModelTypes.ConexionesUTE.field]){
        for (let mejora of fraccion[this.ModelTypes.ConexionesUTE.field]){
          if (this.checkTemporal(mejora)) continue;
          this.checkGeneral(res, mejora);
          this.checkMejorasConEstado(res, mejora);
          this.checkConexionesUTE(res, mejora);
        }
      }
      if (fraccion[this.ModelTypes.TanquesAustralianos.field]){
        for (let mejora of fraccion[this.ModelTypes.TanquesAustralianos.field]){
          if (this.checkTemporal(mejora)) continue;
          this.checkGeneral(res, mejora);
          this.checkMejorasConEstado(res, mejora);
          this.checkTanquesAustralianos(res, mejora);
        }
      }
      if (fraccion[this.ModelTypes.Tanques.field]){
        for (let mejora of fraccion[this.ModelTypes.Tanques.field]){
          if (this.checkTemporal(mejora)) continue;
          this.checkGeneral(res, mejora);
          this.checkMejorasConEstado(res, mejora);
          this.checkTanques(res, mejora);
        }
      }
      if (fraccion[this.ModelTypes.Tubos.field]){
        for (let mejora of fraccion[this.ModelTypes.Tubos.field]){
          if (this.checkTemporal(mejora)) continue;
          this.checkGeneral(res, mejora);
          this.checkMejorasConEstado(res, mejora);
          this.checkTubos(res, mejora);
        }
      }
      if (fraccion[this.ModelTypes.GallinerosChiqueros.field]){
        for (let mejora of fraccion[this.ModelTypes.GallinerosChiqueros.field]){
          if (this.checkTemporal(mejora)) continue;
          this.checkGeneral(res, mejora);
          this.checkMejorasConEstado(res, mejora);
          this.checkGallinerosChiqueros(res, mejora);
        }
      }
      if (fraccion[this.ModelTypes.Corrales.field]){
        for (let mejora of fraccion[this.ModelTypes.Corrales.field]){
          if (this.checkTemporal(mejora)) continue;
          this.checkGeneral(res, mejora);
          this.checkMejorasConEstado(res, mejora);
          this.checkCorrales(res, mejora);
        }
      }
      if (fraccion[this.ModelTypes.CorralesEspera.field]){
        for (let mejora of fraccion[this.ModelTypes.CorralesEspera.field]){
          if (this.checkTemporal(mejora)) continue;
          this.checkGeneral(res, mejora);
          this.checkMejorasConEstado(res, mejora);
          this.checkCorralesEspera(res, mejora);
        }
      }
      if (fraccion[this.ModelTypes.Cepos.field]){
        for (let mejora of fraccion[this.ModelTypes.Cepos.field]){
          if (this.checkTemporal(mejora)) continue;
          this.checkGeneral(res, mejora);
          this.checkMejorasConEstado(res, mejora);
          this.checkCepos(res, mejora);
        }
      }
      if (fraccion[this.ModelTypes.Banios.field]){
        for (let mejora of fraccion[this.ModelTypes.Banios.field]){
          if (this.checkTemporal(mejora)) continue;
          this.checkGeneral(res, mejora);
          this.checkMejorasConEstado(res, mejora);
          this.checkBanios(res, mejora);
        }
      }
      if (fraccion[this.ModelTypes.Embarcaderos.field]){
        for (let mejora of fraccion[this.ModelTypes.Embarcaderos.field]){
          if (this.checkTemporal(mejora)) continue;
          this.checkGeneral(res, mejora);
          this.checkMejorasConEstado(res, mejora);
          this.checkEmbarcaderos(res, mejora);
        }
      }
      if (fraccion[this.ModelTypes.Huevos.field]){
        for (let mejora of fraccion[this.ModelTypes.Huevos.field]){
          if (this.checkTemporal(mejora)) continue;
          this.checkGeneral(res, mejora);
          this.checkMejorasConEstado(res, mejora);
          this.checkHuevos(res, mejora);
        }
      }
      if (fraccion[this.ModelTypes.Bretes.field]){
        for (let mejora of fraccion[this.ModelTypes.Bretes.field]){
          if (this.checkTemporal(mejora)) continue;
          this.checkGeneral(res, mejora);
          this.checkMejorasConEstado(res, mejora);
          this.checkBretes(res, mejora);
        }
      }
      if (fraccion[this.ModelTypes.Porteras.field]){
        for (let mejora of fraccion[this.ModelTypes.Porteras.field]){
          if (this.checkTemporal(mejora)) continue;
          this.checkGeneral(res, mejora);
          this.checkMejorasConEstado(res, mejora);
          this.checkPorteras(res, mejora);
        }
      }
      if (fraccion[this.ModelTypes.MontesNativos.field]){
        for (let mejora of fraccion[this.ModelTypes.MontesNativos.field]){
          if (this.checkTemporal(mejora)) continue;
          this.checkGeneral(res, mejora);
          this.checkMejorasConEstado(res, mejora);
          this.checkGeneralMontes(res, mejora);
          this.checkMontesNativos(res, mejora);
        }
      }
      if (fraccion[this.ModelTypes.MontesForestales.field]){
        for (let mejora of fraccion[this.ModelTypes.MontesForestales.field]){
          if (this.checkTemporal(mejora)) continue;
          this.checkGeneral(res, mejora);
          this.checkMejorasConEstado(res, mejora);
          this.checkGeneralMontes(res, mejora);
          this.checkMontesForestales(res, mejora);
        }
      }
      if (fraccion[this.ModelTypes.MontesFrutales.field]){
        for (let mejora of fraccion[this.ModelTypes.MontesFrutales.field]){
          if (this.checkTemporal(mejora)) continue;
          this.checkGeneral(res, mejora);
          this.checkMejorasConEstado(res, mejora);
          this.checkGeneralMontes(res, mejora);
          this.checkMontesFrutales(res, mejora);
        }
      }
      if (fraccion[this.ModelTypes.Aguadas.field]){
        for (let mejora of fraccion[this.ModelTypes.Aguadas.field]){
          if (this.checkTemporal(mejora)) continue;
          this.checkGeneral(res, mejora);
          this.checkMejorasConEstado(res, mejora);
          this.checkAguadas(res, mejora);
        }
      }
      if (fraccion[this.ModelTypes.Polders.field]){
        for (let mejora of fraccion[this.ModelTypes.Polders.field]){
          if (this.checkTemporal(mejora)) continue;
          this.checkGeneral(res, mejora);
          this.checkMejorasConEstado(res, mejora);
          this.checkAguadas(res, mejora);
          this.checkPolders(res, mejora);
        }
      }
      if (fraccion[this.ModelTypes.OtrasMejoras.field]){
        for (let mejora of fraccion[this.ModelTypes.OtrasMejoras.field]){
          if (this.checkTemporal(mejora)) continue;
          this.checkGeneral(res, mejora);
          this.checkMejorasConEstado(res, mejora);
        }
      }
      if (fraccion[this.ModelTypes.SalasFrio.field]){
        for (let mejora of fraccion[this.ModelTypes.SalasFrio.field]){
          if (this.checkTemporal(mejora)) continue;
          this.checkGeneral(res, mejora);
          this.checkGeneralConstrucciones(res, mejora);
        }
      }

      return res;
    },
    checkFraccionesWrapper: function(res, fraccion){
      if (!fraccion.fraccionesWrapper.puntoAccesoGeoInfo){
//        res.push({id: null, tipo: null, problema: "Falta punto de acceso a la fraccion"});
          console.log("Falta punto de acceso a la fraccion");
      }
    },
    checkGeneral: function (res, mejora) {
      if (mejora.toMove) res.push({id: mejora.id, tipo: mejora.type, problema: "Marcada para moverse"});
      if (mejora.enConstruccion === null) res.push({id: mejora.id, tipo: mejora.type, problema: "Falta En Construccion"});
      if (this.checkIsNull(mejora.propiedadINC)) res.push({id: mejora.id, tipo: mejora.type, problema: "Falta propiedad INC"});
      if (this.checkIsNull(mejora.porcentajePropiedadDelColono) || mejora.porcentajePropiedadDelColono.length == 0){
        res.push({id: mejora.id, tipo: mejora.type, problema: "Falta derecho indemnizatorio"});
      } 
      if (mejora.propiedadINC === false && parseInt(mejora.porcentajePropiedadDelColono) !== 100){
        res.push({id: mejora.id, tipo: mejora.type, problema: "Propiedad colono debe ser 100"});
      } 
      if (mejora.propiedadINC === true && parseInt(mejora.porcentajePropiedadDelColono) === 100){
        res.push({id: mejora.id, tipo: mejora.type, problema: "Propiedad colono no puede ser 100"});
      } 
      if (parseInt(mejora.porcentajePropiedadDelColono) < 0 || parseInt(mejora.porcentajePropiedadDelColono) > 100){
        res.push({id: mejora.id, tipo: mejora.type, problema: "Propiedad colono debe estar entre 0 y 100"});
      } 
      if (!mejora.tiposDeMejorasId) res.push({id: mejora.id, tipo: mejora.type, problema: "Falta tipo de mejora"});
      if (!mejora.fotosSIMCollection) res.push({id: mejora.id, tipo: mejora.type, problema: "Falta fotos"});
//      if (!mejora.idMejoraOriginal) res.push({id: mejora.id, tipo: mejora.type, problema: "Falta id mejora original"});
      if (!mejora.timestamp || (mejora.timestamp && parseInt(mejora.timestamp) === 0)) res.push({id: mejora.id, tipo: mejora.type, problema: "Falta timestamp"});
      if (!mejora.geoInfo) res.push({id: mejora.id, tipo: mejora.type, problema: "Falta geometria"});
    },
    checkGeneralConstrucciones: function(res, mejora){
//      if (!mejora.tiposDeConstruccionId) res.push({id: mejora.id, tipo: mejora.type, problema: "Falta tipo de construccion"});
      if (!mejora.tiposDeMaterialesParedesId) res.push({id: mejora.id, tipo: mejora.type, problema: "Falta material tiposDeMaterialesParedes"});
      if (!mejora.tiposDeMaterialesTechosId) res.push({id: mejora.id, tipo: mejora.type, problema: "Falta material techos"});
      if (!mejora.tiposDeMaterialesPisosId) res.push({id: mejora.id, tipo: mejora.type, problema: "Falta material pisos"});
      if (!mejora.tiposDeEstadoConservacionConstruccionesId) res.push({id: mejora.id, tipo: mejora.type, problema: "Falta estado construccion"});
      if (!mejora.metrosCuadrados) res.push({id: mejora.id, tipo: mejora.type, problema: "Falta metros cuadrados"});
      if (mejora.metrosCuadrados && (parseInt(mejora.metrosCuadrados) < 9 || parseInt(mejora.metrosCuadrados) > 999)) res.push({id: mejora.id, tipo: mejora.type, problema: "Metros cuadrados no esta entr 9 y 999"});
      if (mejora.mevir === undefined || mejora.mevir === null) res.push({id: mejora.id, tipo: mejora.type, problema: "Falta mevir"});
      if (!mejora.anioConstruccion) res.push({id: mejora.id, tipo: mejora.type, problema: "Falta año construccion"});
      if ((parseInt(mejora.anioConstruccion) < 1900 || parseInt(mejora.anioConstruccion) > 2050)) res.push({id: mejora.id, tipo: mejora.type, problema: "Año construccion debe estar entre 1900 y 2050"});

    },
    checkMejorasConEstado: function(res, mejora){
      if (!mejora.tiposDeEstadosConservacionMejorasId) res.push({id: mejora.id, tipo: mejora.type, problema: "Falta estado de conservacion"});
    },
    checkViviendas: function(res, mejora){
      if (!mejora.tiposDeViviendaId) res.push({id: mejora.id, tipo: mejora.type, problema: "Falta tipo de vivienda"});
      if (mejora.tieneBanio === null) res.push({id: mejora.id, tipo: mejora.type, problema: "Falta baño"});
      if (mejora.tieneExcusado === null) res.push({id: mejora.id, tipo: mejora.type, problema: "Falta excusado"});
      if (mejora.tieneInstalacionElectrica === null) res.push({id: mejora.id, tipo: mejora.type, problema: "Falta instalacion electrica"});
      if (mejora.tieneInstalacionAgua === null) res.push({id: mejora.id, tipo: mejora.type, problema: "Falta instalacion agua"});
      if (!mejora.cantidadDormitorios) res.push({id: mejora.id, tipo: mejora.type, problema: "Falta cantidad dormitorios"});
      if (mejora.cantidadDormitorios && parseInt(mejora.cantidadDormitorios) > 10) res.push({id: mejora.id, tipo: mejora.type, problema: "Cantidad dormitorios > 10"});
    },
    checkGalpones: function(res, mejora){
      if (!mejora.tiposDeGalponId) res.push({id: mejora.id, tipo: mejora.type, problema: "Falta tipo de galpon"});
    },
    checkSalasOrdene: function(res, mejora){
      if (!mejora.tiposDeTambosSIMId) res.push({id: mejora.id, tipo: mejora.type, problema: "Falta tipo de tambo"});
    },
    checkAlambrados: function(res, mejora){
      if (!mejora.tiposDeAlambradosId) res.push({id: mejora.id, tipo: mejora.type, problema: "Falta tipo de alambrado"});
      if (!mejora.tiposDeEstadosConservacionAlambradosId) res.push({id: mejora.id, tipo: mejora.type, problema: "Falta estado conservacion"});
      if (!mejora.largo) res.push({id: mejora.id, tipo: mejora.type, problema: "Falta largo"});
      if (!mejora.cantidadHilos) res.push({id: mejora.id, tipo: mejora.type, problema: "Falta cantidad hilos"});
    },
    checkGuardaPatios: function(res, mejora){
      if (!mejora.tiposDeEstadosConservacionAlambradosId) res.push({id: mejora.id, tipo: mejora.type, problema: "Falta tipo de estado conservacion"});
      if (!mejora.tiposDeMaterialesGuardaPatiosId) res.push({id: mejora.id, tipo: mejora.type, problema: "Falta tipo de material"});
      if (!mejora.largo) res.push({id: mejora.id, tipo: mejora.type, problema: "Falta largo"});
      // si tipo material Alambre if (!mejora.cantidadHilos) res.push({id: mejora.id, tipo: mejora.type, problema: "Falta cantidad hilos"});
    },
    checkDecantadores: function(res, mejora){
      if (mejora.capacidadFosa > 0 && !mejora.tiposDeEstadosConservacionFosaId) res.push({id: mejora.id, tipo: mejora.type, problema: "Falta estado de conservacion fosa"});
      if (mejora.capacidadCamara > 0 && !mejora.tiposDeEstadosConservacionCamaraId) res.push({id: mejora.id, tipo: mejora.type, problema: "Falta estado de conservacion camara"});
    },
    checkRiego: function(res, mejora){
      if (!mejora.caracteristicas) res.push({id: mejora.id, tipo: mejora.type, problema: "Faltan caracteristicas"});
    },
    checkCaminos: function(res, mejora){
      if (!mejora.tiposDeMaterialesCaminosId) res.push({id: mejora.id, tipo: mejora.type, problema: "Falta material camino"});
      if (!mejora.largo) res.push({id: mejora.id, tipo: mejora.type, problema: "Falta largo"});
      if (!mejora.ancho) res.push({id: mejora.id, tipo: mejora.type, problema: "Falta ancho"});
    },
    checkPozos: function(res, mejora){
      if (!mejora.tiposDePozoId) res.push({id: mejora.id, tipo: mejora.type, problema: "Falta tipo pozo"});
      if (!mejora.metodoDeExtraccionAguaId) res.push({id: mejora.id, tipo: mejora.type, problema: "Falta metodo extraccion agua"});
      if (!mejora.profundidad) res.push({id: mejora.id, tipo: mejora.type, problema: "Falta profundidad"});
    },
    checkPozosBalde: function(res, mejora){
      if (mejora.tieneBrocal === null) res.push({id: mejora.id, tipo: mejora.type, problema: "Falta brocal"});
    },
    checkMolinos: function(res, mejora){
      if (!mejora.altura) res.push({id: mejora.id, tipo: mejora.type, problema: "Falta altura"});
      if (mejora.enFuncionamiento === null) res.push({id: mejora.id, tipo: mejora.type, problema: "Falta funcionamiento"});
    },
    checkBombas: function(res, mejora){
      if (!mejora.potenciaHP) res.push({id: mejora.id, tipo: mejora.type, problema: "Falta potencia"});
      if (mejora.enFuncionamiento === null) res.push({id: mejora.id, tipo: mejora.type, problema: "Falta funcionamiento"});
    },
    checkBebederosComederos: function(res, mejora){
      if (!mejora.tiposDeAlimentadoresId) res.push({id: mejora.id, tipo: mejora.type, problema: "Falta tipo alimentador"});
      if (!mejora.tiposDeMaterialDeBebederosId) res.push({id: mejora.id, tipo: mejora.type, problema: "Falta material bebedero"});
      if (!mejora.capacidad) res.push({id: mejora.id, tipo: mejora.type, problema: "Falta capacidad"});
    },
    checkConexionesUTE: function(res, mejora){
      if (!mejora.tiposDeConexionUTEId) res.push({id: mejora.id, tipo: mejora.type, problema: "Falta tipo conexion"});
      if (!mejora.distanciaAlContador) res.push({id: mejora.id, tipo: mejora.type, problema: "Falta distancia al contador"});
    },
    checkTanquesAustralianos: function(res, mejora){
      if (!mejora.tiposDeMaterialesTanqueAustralianoId) res.push({id: mejora.id, tipo: mejora.type, problema: "Falta tipo material"});
//   si tipo material es Chapa   if (!mejora.cantidadDeChapas) res.push({id: mejora.id, tipo: mejora.type, problema: "Falta cantidad de chapas"});
      if (!mejora.capacidad) res.push({id: mejora.id, tipo: mejora.type, problema: "Falta capacidad"});
      if (mejora.enFuncionamiento === null) res.push({id: mejora.id, tipo: mejora.type, problema: "Falta en funcionamiento"});
    },
    checkTanques: function(res, mejora){
      if (!mejora.tiposDeTanqueId) res.push({id: mejora.id, tipo: mejora.type, problema: "Falta tipo de tanque"});
      if (!mejora.tiposDeMaterialesTanqueId) res.push({id: mejora.id, tipo: mejora.type, problema: "Falta tipo de material"});
      if (!mejora.capacidad) res.push({id: mejora.id, tipo: mejora.type, problema: "Falta capacidad"});
      if (mejora.soporte === null) res.push({id: mejora.id, tipo: mejora.type, problema: "Falta soporte"});
    },
    checkTubos: function(res, mejora){
      if (!mejora.tiposDeUsoTubosId) res.push({id: mejora.id, tipo: mejora.type, problema: "Falta tipo de uso"});
      if (!mejora.largo) res.push({id: mejora.id, tipo: mejora.type, problema: "Falta largo"});
// si tipo uso Vacuno     if (mejora.tieneCepo === null) res.push({id: mejora.id, tipo: mejora.type, problema: "Falta tiene cepo"});
    },
    checkGallinerosChiqueros: function(res, mejora){
      if (!mejora.tiposDeGallineroChiqueroId) res.push({id: mejora.id, tipo: mejora.type, problema: "Falta tipo"});
      if (!mejora.metrosCuadrados) res.push({id: mejora.id, tipo: mejora.type, problema: "Falta metros cuadrados"});
      if (!mejora.tiposDeMaterialesGallineroId) res.push({id: mejora.id, tipo: mejora.type, problema: "Falta tipo de material"}); 
    },
    checkCorrales: function(res, mejora){
      if (!mejora.tiposDeCorralesId) res.push({id: mejora.id, tipo: mejora.type, problema: "Falta tipo de corral"});
      if (!mejora.tiposDeMaterialesPisosCorralId) res.push({id: mejora.id, tipo: mejora.type, problema: "Falta material pisos"});
      if (!mejora.tiposDeMaterialesParedesCorralId) res.push({id: mejora.id, tipo: mejora.type, problema: "Falta material tiposDeMaterialesParedes"});
      if (!mejora.perimetro) res.push({id: mejora.id, tipo: mejora.type, problema: "Falta perimetro"});
      if (mejora.tiposDeMaterialesPisosCorralId && mejora.tiposDeMaterialesParedesCorralId && !mejora.cantidadDeHilos) 
        res.push({id: mejora.id, tipo: mejora.type, problema: "Falta cantidad hilos"});
    },    
    checkCorralesEspera: function(res, mejora){
      if (!mejora.superficie) res.push({id: mejora.id, tipo: mejora.type, problema: "Falta superficie"});
      if (mejora.mevir === null) res.push({id: mejora.id, tipo: mejora.type, problema: "Falta mevir"});
    },
    checkCepos: function(res, mejora){
      if (!mejora.tiposDeMaterialesCepoId) res.push({id: mejora.id, tipo: mejora.type, problema: "Falta tipo de material"});
    },
    checkBanios: function(res, mejora){
      if (!mejora.tiposDeUsoBanioParaGanadoId) res.push({id: mejora.id, tipo: mejora.type, problema: "Falta tipo de uso"});
      if (!mejora.tiposDeBanioGanadoId) res.push({id: mejora.id, tipo: mejora.type, problema: "Falta tipo de baño"});
      if (!mejora.capacidad) res.push({id: mejora.id, tipo: mejora.type, problema: "Falta capacidad"}); 
    },
    checkEmbarcaderos: function(res, mejora){
      if (!mejora.tiposDeUsoEmbarcaderoId) res.push({id: mejora.id, tipo: mejora.type, problema: "Falta tipo de uso"});
      if (!mejora.tiposDeEmbarcaderoId) res.push({id: mejora.id, tipo: mejora.type, problema: "Falta tipo de embarcadero"});
      if (!mejora.tiposDeMaterialesParedesEmbarcaderoId) res.push({id: mejora.id, tipo: mejora.type, problema: "Falta material tiposDeMaterialesParedes"}); 
      if (!mejora.tiposDeMaterialesPisosEmbarcaderoId) res.push({id: mejora.id, tipo: mejora.type, problema: "Falta material piso"}); 
    },
    checkHuevos: function(res, mejora){
      if (!mejora.tiposDeMaterialesHuevoId) res.push({id: mejora.id, tipo: mejora.type, problema: "Falta tipo de material"});
      // si material alambre if (!mejora.cantidadDeHilos) res.push({id: mejora.id, tipo: mejora.type, problema: "Falta cantidad de hilos"});           
    },    
    checkBretes: function(res, mejora){
      if (!mejora.tiposDeBretesId) res.push({id: mejora.id, tipo: mejora.type, problema: "Falta tipo"});
      if (!mejora.tiposDeMaterialesParedesBreteId) res.push({id: mejora.id, tipo: mejora.type, problema: "Falta material tiposDeMaterialesParedes"});
      if (!mejora.tiposDeMaterialesPisosBreteId) res.push({id: mejora.id, tipo: mejora.type, problema: "Falta material piso"}); 
      if (!mejora.perimetro) res.push({id: mejora.id, tipo: mejora.type, problema: "Falta perimetro"}); 
      // si material alambre if (!mejora.cantidadDeHilos) res.push({id: mejora.id, tipo: mejora.type, problema: "Falta cantidad de hilos"});     
    },
    checkPorteras: function(res, mejora){
      if (!mejora.tiposDeUsoPorteraId) res.push({id: mejora.id, tipo: mejora.type, problema: "Falta tipo de uso"});
      if (!mejora.cantidadHojas) res.push({id: mejora.id, tipo: mejora.type, problema: "Falta cantidad hojas"});
      if (!mejora.ancho) res.push({id: mejora.id, tipo: mejora.type, problema: "Falta ancho"}); 
      if (!mejora.alto) res.push({id: mejora.id, tipo: mejora.type, problema: "Falta alto"}); 
    },
    checkGeneralMontes: function(res, mejora){
//      if (!mejora.tiposDeMontesId) res.push({id: mejora.id, tipo: mejora.type, problema: "Falta tipo de monte"});
      if (!mejora.tiposDeEstadoSanitarioId) res.push({id: mejora.id, tipo: mejora.type, problema: "Falta estado sanitario"});
      if (!mejora.superficie) res.push({id: mejora.id, tipo: mejora.type, problema: "Falta superficie"}); 
    },
    checkMontesNativos: function(res, mejora){
      if (mejora.registradoDireccionForestal === null) res.push({id: mejora.id, tipo: mejora.type, problema: "Falta registro en direccion forestal"}); 
    },
    checkMontesForestales: function(res, mejora){
      if (!mejora.cantidadDeArboles) res.push({id: mejora.id, tipo: mejora.type, problema: "Falta cantidad de arboles"});
      if (!mejora.dap) res.push({id: mejora.id, tipo: mejora.type, problema: "Falta DAP"});
      if (mejora.tieneRebrote === null) res.push({id: mejora.id, tipo: mejora.type, problema: "Falta tiene rebrote"}); 
      if (!mejora.alturaMonte) res.push({id: mejora.id, tipo: mejora.type, problema: "Falta altura"});
      if (!mejora.tiposDeEspeciesMontesForestalesId) res.push({id: mejora.id, tipo: mejora.type, problema: "Falta tipo de especie"});
      if (!mejora.edad) res.push({id: mejora.id, tipo: mejora.type, problema: "Falta edad"}); 
    },
    checkMontesFrutales: function(res, mejora){
      if (!mejora.cantidadDeArboles) res.push({id: mejora.id, tipo: mejora.type, problema: "Falta cantidad de arboles"});
      if (!mejora.edad) res.push({id: mejora.id, tipo: mejora.type, problema: "Falta edad"}); 
      if (!mejora.tiposDeEspeciesMontesFrutalesId) res.push({id: mejora.id, tipo: mejora.type, problema: "Falta tipo de especie"});
    },
    checkAguadas: function(res, mejora){
      if (!mejora.largo) res.push({id: mejora.id, tipo: mejora.type, problema: "Falta largo"});
      if (!mejora.altura) res.push({id: mejora.id, tipo: mejora.type, problema: "Falta altura"});
      if (!mejora.tiposDeReservaDeAguaId) res.push({id: mejora.id, tipo: mejora.type, problema: "Falta tipo"}); 
    },
    checkPolders: function(res, mejora){
      if (!mejora.ancho) res.push({id: mejora.id, tipo: mejora.type, problema: "Falta ancho"});
      if (!mejora.profundidad) res.push({id: mejora.id, tipo: mejora.type, problema: "Falta profundidad"});
      },
  checkTemporal: function(mejora){
      return mejora.deleted;
      // && mejora.id.toString().startsWith(this.General.tmpId);
    },
    checkIsNull: function(prop){
      return (prop === null || prop === undefined);
    }
  }
}