<template>
  <v-app>
    <v-app-bar app>
      <v-toolbar-title>Inventario de Mejoras</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        :disabled="(wrkState.edit !== null) || $route.name == 'Login'"
        x-small
        @click="gotoHome"
        ><v-icon>mdi-home</v-icon></v-btn
      >
      <v-spacer></v-spacer>
      <span>{{ editedTypes }}</span>
      <v-icon v-if="!wrkState.edit">mdi-file-edit</v-icon>
      <v-icon v-if="wrkState.edit">mdi-file-edit-outline</v-icon>
      <span>{{ wrkState.selected ? wrkState.selected.type : "" }}</span>
      <v-icon v-if="!wrkState.selected">mdi-clipboard</v-icon>
      <v-icon v-if="wrkState.selected">mdi-clipboard-outline</v-icon>
    </v-app-bar>

    <v-content>
      <v-snackbar
        bottom
        :value="updateExists"
        :timeout="0"
        color="primary"
      >
        Una actualizacion esta disponible (no actualizar en edicion)
        <v-btn :disabled="$route.name === 'Map'" text @click="refreshApp"> Actualizar </v-btn>
      </v-snackbar>
      <v-container fluid>
        <keep-alive include="Map">
          <router-view></router-view>
        </keep-alive>
      </v-container>
    </v-content>

    <v-footer padless app>
      <ConnectionStatus />
    </v-footer>
  </v-app>
</template>

<script>
import "@mdi/font/css/materialdesignicons.css";
import { mapState } from "vuex";
import update from "./mixins/update";
import ConnectionStatus from "./components/ConnectionStatus";

export default {
  name: "App",
  components: {ConnectionStatus},
  mixins: [ update ],
  data() {
    return {
      editedTypes: ""
    };
  },
  methods: {
    gotoHome: function(){
      if (this.$route.name !== 'Home'){
        this.$store.dispatch('setWrkSelected', null);
        this.$router.push('home');      
      }
    }
  },
  computed: {
    currentRouteName() {
        console.log(this.$router);
        return this.$router.name;
    },    
    ...mapState(["wrkState"])
  },
  watch: {
    wrkState() {
      this.editedTypes = "";

      if (this.wrkState.edit) {
        this.wrkState.edit.type.forEach((element) => {
          //          console.log(element);
          this.editedTypes = this.editedTypes.concat(element + " ");
        });
      }
    },
  },
};
</script>
