export default {
    data(){
        return {
            num_range(propertyName, since, then) {
                return (v) => {
                  if (!v || (v && v >= since && v <= then)){
                    return true;
                  } else {
                   return `${propertyName} debe estar entre ${since} y ${then}`;
                  }
                }
              },
              required(propertyName){
                return (v) => {
//                  console.log(propertyName + ': ' + JSON.stringify(v));
                  if (v){
                    return true;
                  } else {
                    return `${propertyName} es requerido`;
                  }
                }
              },
              positive(propertyName){
                return (v) => {
//                  console.log(propertyName + ': ' + JSON.stringify(v));
                  if (v && v > 0 && !isNaN(v)){
                    return true;
                  } else {
                    return `${propertyName} debe ser mayor que 0`;
                  }
                }
              },
              positiveAndInteger(propertyName){
                return (v) => {
//                  console.log(propertyName + ': ' + JSON.stringify(v));
                  if (v && v > 0 && !isNaN(v) && Number.isInteger(parseFloat(v))){
                    return true;
                  } else {
                    return `${propertyName} debe ser mayor que 0 y entero`;
                  }
                }
              },
              isInteger(propertyName){
                return (v) => {
//                  console.log(propertyName + ': ' + JSON.stringify(v));
                  if (!v || (v && v > 0 && !isNaN(v) && Number.isInteger(parseFloat(v)))){
                    return true;
                  } else {
                    return `${propertyName} debe ser mayor que 0 y entero`;
                  }
                }
              },
              requiredBool(propertyName){
                return (v) => {
//                  console.log(propertyName + ': ' + JSON.stringify(v));

                  if (v == undefined || typeof v !== 'boolean'){
                    return `${propertyName} es requerido`;
                  } else {
                    return true;
                  }
                }
              }          
        }
    },
    methods: {
      validateForm: function(){
        if (this.$refs.form){
          this.$refs.form.validate();        
        }
      }
    }
}