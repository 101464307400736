import Vue from 'vue';
import VueRouter from 'vue-router'
import Map from '@/components/map/Map';
import Home from '@/components/Home';
import Login from '@/components/Login';

Vue.use(VueRouter);
//const routerHistory = createWebHistory();

const router = new VueRouter({
//    history: routerHistory,
    routes: [
      {
        path: '/home',
        name: 'Home',
        component: Home
      },
      {
        path: '/',
        name: 'Login',
        component: Login
      },
      {
        path: '/map',
        name: 'Map',
        component: Map
      }
    ]
  });

export default router;  