<template>
  <v-row justify="center">
    <v-dialog ref="dialog" class="over" v-model="showDialog" width="500">
      <v-card>
        <v-card-title>Fracciones descargadas</v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-data-table
                  :headers="headers"
                  :items="fraccToMove"
                  v-model="selected"
                  :single-select="true"
                  show-select
                  locale="es"
                  class="elevation-1"
                >
                  <template v-slot:[`item.exported`]="{ item }">
                    <span>{{
                      item.exported
                        ? new Date(item.exported).toLocaleString()
                        : null
                    }}</span>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions center>
          <v-spacer></v-spacer>
          <v-btn :loading="moving" :disabled = "!isSelected" @click="transfer">Transferir</v-btn>
          <v-btn :disabled = "isMarked || moving" @click="mark">Marcar</v-btn>
          <v-btn :disabled = "!isMarked || moving" @click="unMark">Desmarcar</v-btn>
          <v-btn :disabled = "moving" @click="showDialog = false">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import "@mdi/font/css/materialdesignicons.css";
import { mapState } from "vuex";

export default {
  name: "MoverMejora",
  props: ["show"],
  data() {
    return {
      moving: false,
      headers: [
        {
          text: "Fraccion",
          value: "fraccionesWrapper.numeroFraccion",
          align: "end",
        },
        {
          text: "Colonia",
          value: "fraccionesWrapper.nombreColonia",
          align: "start",
          sortable: true,
        },
        {
          text: "Inmueble",
          value: "fraccionesWrapper.numeroInmueble",
          align: "start",
          sortable: true,
        },
        {
          text: "Fecha ultima exportacion",
          value: "exported",
          align: "start",
        },
      ],
      selected: [],
    };
  },
  methods: {
    transfer: function(){
      let geoInfo = this.getMejora().geoInfo;

      let opts = {
        fracOri: this.wrkFraccion.id,
        fracDest: this.selected[0].id,
        idMejora: this.wrkState.selected.id,
        geoInfo: geoInfo[1] + "," + geoInfo[0],
        field: this.getType(),
        usuario: 'sim'
      };
      console.log(opts);
      this.moving = true;

      this.$moverMejora(opts)
        .then(() => {
          console.log("Mejora transferida en servidor...");
          return this.$store.dispatch('moverMejora', opts);
        })
        .then(() => {
          console.log("Mejora transferida en SIM...");
          this.$emit("close");
          return this.$toasted.show("Mejora transferida", {
            type: "success",
            duration: 5000,
          });
        })
        .catch((error) => {
          console.log(error);
          return this.$toasted.show("Error al transferir mejora: " + error, {
            type: "error",
            duration: 3000,
          });
        })
        .finally(() => {
          this.moving = false;
        });

    },
    mark: function(){
      let opts = {
        toMove: true,
        idFraccion: this.wrkFraccion.id,
        idMejora: this.wrkState.selected.id,
        field: this.ModelTypes[this.wrkState.selected.type].field
      };
      this.$store.dispatch('markMejora', opts)
        .then(() => {
          this.$emit("close");
          return this.$toasted.show("Mejora marcada", {
            type: "success",
            duration: 3000,
          });
        })
        .catch((error) => {
          return this.$toasted.show("Error al marcar mejora: " + error, {
            type: "error",
            duration: 3000,
          });
        });
    },
    unMark: function(){
      let opts = {
        toMove: false,
        idFraccion: this.wrkFraccion.id,
        idMejora: this.wrkState.selected.id,
        field: this.ModelTypes[this.wrkState.selected.type].field
      };
      this.$store.dispatch('markMejora', opts)
        .then(() => {
          this.$emit("close");
          return this.$toasted.show("Mejora desmarcada", {
            type: "success",
            duration: 3000,
          });
        })
        .catch((error) => {
          return this.$toasted.show("Error al desmarcar mejora: " + error, {
            type: "error",
            duration: 3000,
          });
        });
    },
    getMejora: function(){
      let res = null;

      if (this.wrkState && this.wrkState.selected && this.wrkState.selected.id){
        let field = this.getType();
        if (field && this.wrkFraccion[field]){
          res = this.wrkFraccion[field].find(el => el.id === this.wrkState.selected.id);
        }
      }

      return res;
    },
    getType: function(){
      let type = this.$findTypeByName(this.wrkState.selected.type);

      return type.field;
    }
  },
  computed: {
    fraccToMove(){
      return this.wrkFraccion ? this.fracciones.filter(elem => elem.id !== this.wrkFraccion.id) : [];
    },
    isSelected(){
      return this.wrkState.selected && this.selected.length > 0;
    },
    isMarked(){
      let res = false;

      if (this.wrkState && this.wrkState.selected && this.wrkState.selected.id){
        let type = this.$findTypeByName(this.wrkState.selected.type);
        if (type && this.wrkFraccion[type.field]){
          let element = this.wrkFraccion[type.field].find(el => el.id === this.wrkState.selected.id);
          res = (element && element.toMove) ? true:false;
        }
      }

      return res;
    },
    showDialog: {
      get() {
        return this.show;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
    ...mapState(["fracciones", "wrkFraccion", "wrkState"]),
  },
};
</script>
<style scoped>
.over {
  z-index: 9000 !important;
}
</style>
