<template>
      <v-dialog ref="dialog" class="over" v-model="showDialog" width="600" persistent>
        <v-card>
          <v-card-title>Problemas detectados al chequear la fraccion</v-card-title>
          <v-card-text>
          <v-container>
            <v-row justify="center">
              <v-data-table
                :headers="[
                  { text: 'Id Mejora', value: 'id', align: 'end' },
                  { text: 'Tipo', value: 'tipo', align: 'start' },
                  { text: 'Problema', value: 'problema', align: 'start' },
                ]"
                item-key=""
                :items="errors"
                locale="es"
                class="elevation-1"
              >
              </v-data-table>
            </v-row>
          </v-container>
          </v-card-text>
          <v-card-actions center>
            <v-spacer></v-spacer>
            <v-btn @click="$emit('closeValidation')">Cerrar</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
</template>

<script>
import "@mdi/font/css/materialdesignicons.css";

export default {
  name: "ValidationUI",
  props: ["errors", "dialog"],
  data() {
    return {
    };
  },
  methods: {},
  computed: {
    showDialog: function(){
      return this.dialog;
    }
  }
};
</script>
