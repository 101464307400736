import {Buffer} from 'buffer';

export default {
    methods: {
        downloadFotosFraccion: function(fraccion){
            let toDownload =  0;
            let promises = [];
            let size = 0;
      //      let downloaded =  0;
      
            for (const key of Object.keys(this.ModelTypes)) {
              let field = this.ModelTypes[key].field;
      
              if (fraccion[field]) {
      //          console.log('Bajando fotos de ' + field);
                for (let mejora of fraccion[field]){
                  if (mejora.fotosSIMCollection){
                    for (let foto of mejora.fotosSIMCollection){
                      if (foto.img_path && !foto.fotoFisica){

                        let name = foto.img_path.split('imagenes')[1];

                        if (!foto.id){
                          let msg = key + ": " + name + " no tiene el ID de la foto, no se puede descargar...";
//                          console.log(msg);
                          this.$toasted.show(msg, {type: "info", duration: 6000});
                        } else {
                          toDownload++;
                          //let path = foto.img_path.substr(0, foto.img_path.lastIndexOf('.'));
  //                        console.log('descargando foto en ' + field);
                          promises.push(
                          this.$getFoto(foto.img_path).then((res) => {
                            let len = Math.round(Buffer.from(res.substring(res.indexOf(','))).length / 1024);
                            size += len;
                            let msg = name + ': ' + len + ' Kb';
//                            this.$toasted.show(msg, {type: "info", duration: 4000});
                            console.log(msg);
                            return this.$store.dispatch("setImage", {
                              idFraccion: fraccion.id, 
                              field: field,
                              idMejora: mejora.id, 
                              idFoto: foto.id, 
                              image: res
                            });
  //                          console.log(field + ': ' + name + ' descargada');
                          }));
                        }
                      }
      //                break; // descargando solo 1 foto por tipo de mejora
                    }
                  }
      //            break; // descargando solo del primer tipo de mejora
                }
              }
            }
            if (toDownload === 0){
              let msg = "Fraccion " + fraccion.fraccionesWrapper.numeroFraccion +
              ", inmueble " + fraccion.fraccionesWrapper.numeroInmueble +
              ": sin fotos para descargar y/o ya estan descargadas...";

              console.log(msg);
              return new Promise((resolve) => { 
                this.$toasted.show(msg, {type: "info", duration: 6000});
                resolve();
              });
            } else {

              return Promise.all(promises)
              .then(() => {
                let msg = "Fotos descargadas de la fraccion " + fraccion.fraccionesWrapper.numeroFraccion +
                ", inmueble " + fraccion.fraccionesWrapper.numeroInmueble + "( " + size + " Kb)";

                console.log(msg);
                this.$toasted.show(msg, {type: "info", duration: 6000});
              })
              .catch((exception) => {
                let msg = exception.toString();

                console.log(msg);
                this.$toasted.show(msg, {type: "error", duration: 4000});
              });  
            }
      /*
             else {
              this.$toasted.show(downloaded + " / " + toDownload + " fotos descargadas...", {type: (toDownload === downloaded) ? "info" : "error", duration: 4000});
            }
      */      
          },
          uploadFotosFraccion: function(fraccion){
            let toUpload =  0;
            let promises = [];

            for (const key of Object.keys(this.ModelTypes)) {
              let field = this.ModelTypes[key].field;
      
              if (fraccion[field]) {
                for (let mejora of fraccion[field]){
                  if (mejora.fotosSIMCollection){
                    for (let foto of mejora.fotosSIMCollection){
                      if (!foto.img_path && foto.fotoFisica){
                        toUpload++;
                        let opts = {
                          idFraccion: fraccion.id,
                          nombreFoto: foto.id,
                          foto: foto.fotoFisica.substring(foto.fotoFisica.indexOf(','))
                        };
                        promises.push(
                        this.$setFoto(opts).then((res) => {
                          return this.$store.dispatch("setImagePath", {
                            idFraccion: fraccion.id, 
                            field: field,
                            idMejora: mejora.id, 
                            idFoto: foto.id, 
                            imagePath: res
                          });
                        })
                        )
                      }
                    }
                  }
                }
              }
            }
            if (toUpload === 0){
              let msg = "Fraccion " + fraccion.fraccionesWrapper.numeroFraccion +
              ", inmueble " + fraccion.fraccionesWrapper.numeroInmueble +
              ": sin fotos para subir...";

              console.log(msg);
              return new Promise((resolve) => {
                this.$toasted.show(msg, {type: "info", duration: 5000});

                resolve(true);
              });
            } else {
              console.log("subiendo fotos...");
              return Promise.all(promises);
            }
          }
    }
};
