import Vue from "vue";

export default {
  SETCONNECTIONSTATUS(state, payload) {
    state.connectionStatus = payload;
  },
  RESETSTATE(state, payload) {
    Object.assign(state, payload);
  },
  DATOSOPERATIVOS(state, payload) {
    for (const key of Object.keys(payload)) {
      state[key] = payload[key];
      Vue.set(state, key, payload[key]);
    }
    state.datosOperativosTimestamp = Date.now();
  },
  ADDFRACCION(state, newFraccion) {
    if (!state.fracciones) {
      state.fracciones = [];
    }

    state.fracciones.push(newFraccion);
  },
  UPDATEFRACCION(state) {
    let index = state.fracciones.findIndex(todo => todo.id === state.wrkFraccion.id);

    state.fracciones[index] = JSON.parse(JSON.stringify(state.wrkFraccion));
  },
  RESTOREFRACCION(state) {
    let fraccion = state.fracciones.find(todo => todo.id === state.wrkFraccion.id);

    state.wrkFraccion = JSON.parse(JSON.stringify(fraccion));
    state.wrkAlamSelected = null;
    state.wrkVivSelected = null;
  },
  MERGEFRACCION(state, payload) {
    var oldFraccion = state.fracciones.find(todo => todo.fraccion === payload.fraccion);
    var index = state.fracciones.findIndex(todo => todo.fraccion === payload.fraccion);

    var newFraccion = Object.assign(oldFraccion, payload);

    state.fracciones.splice(index, 1, newFraccion);
  },
  CLEARFRACCIONES(state) {
    state.fracciones = [];
    state.wrkFraccion = null;
    state.wrkState = {
      edit: null,
      refresh: null,
      selected: null
    }
  },
  CLEARFRACCION(state, payload) {
    let index = state.fracciones.findIndex(todo => todo.id === payload);

    if (index > -1) {
      Vue.delete(state.fracciones, index);
      //          state.fracciones.splice(index, 1);
      if (state.wrkFraccion && payload === state.wrkFraccion.id) {
        console.log("borrando fraccion temporal...");
        state.wrkFraccion = {};
        state.wrkState = {
          edit: null,
          refresh: null,
          selected: null
        }
      }
    }
  },
  SETWRKFRACCION(state, payload) {
    let fraccion = state.fracciones.find(todo => todo.id === payload.id);
    state.wrkFraccion = JSON.parse(JSON.stringify(fraccion));
    state.wrkState = {
      edit: null,
      refresh: null,
      selected: null
    }
  },
  SETWRKEDIT(state, payload) {
    let tmp = {
      edit: payload,
      refresh: state.wrkState.refresh,
      selected: state.wrkState.selected
    };

    state.wrkState = tmp;
  },
  SETWRKREFRESH(state, payload) {
    let tmp = {
      edit: state.wrkState.edit,
      refresh: payload,
      selected: state.wrkState.selected
    };

    state.wrkState = tmp;
  },
  SETWRKSELECT(state, payload) {
    let tmp = {
      edit: state.wrkState.edit,
      refresh: state.wrkState.refresh,
      selected: payload
    };

    state.wrkState = tmp;
  },
  SETWRKSTYLESIZE(state, payload) {
    state.wrkStyleSize = payload;
  },
  SETEXPORTEDFRACCION(state, payload) {
    let index = state.fracciones.findIndex(todo => todo.id === payload.id);

    state.fracciones[index].exported = Date.now();
    if (payload.idInventario) {
      state.fracciones[index].idInventario = payload.idInventario;
    }
  },
  SETIMAGE(state, payload) {
    let fraccion = state.fracciones.find(todo => todo.id === payload.idFraccion);
    let mejora = fraccion[payload.field].find(todo => todo.id === payload.idMejora);
    let foto = mejora.fotosSIMCollection.find(todo => todo.id === payload.idFoto);
    let isOnlyEdited = payload.onlyEdited !== undefined && payload.onlyEdited !== null && payload.onlyEdited === true

    if (!isOnlyEdited) {
      if (!foto) {
        foto = {
          id: payload.idFoto,
          img_path: null,
          img_path_temp: null,
          geoLatitude: payload.lat,
          geoLongitude: payload.long,
          geoAltitude: null,
          geoAccuracy: null,
          geoAltitudeAccuracy: null,
          geoHeading: null,
          geoTimestamp: null,
          fotoFisica: null
        }

        mejora.fotosSIMCollection.push(foto);
      }
      foto.fotoFisica = payload.image;
    }

    if (state.wrkFraccion && state.wrkFraccion.id === payload.idFraccion) {
      mejora = state.wrkFraccion[payload.field].find(todo => todo.id === payload.idMejora);
      foto = mejora.fotosSIMCollection.find(todo => todo.id === payload.idFoto);
      if (!foto) {
        foto = {
          id: payload.idFoto,
          img_path: null,
          img_path_temp: null,
          geoLatitude: payload.lat,
          geoLongitude: payload.long,
          geoAltitude: null,
          geoAccuracy: null,
          geoAltitudeAccuracy: null,
          geoHeading: null,
          geoTimestamp: null,
          fotoFisica: null
        }

        mejora.fotosSIMCollection.push(foto);
      }

      foto.fotoFisica = payload.image;
    }
  },
  SETIMAGEPATH(state, payload) {
    let fraccion = state.fracciones.find(todo => todo.id === payload.idFraccion);
    let mejora = fraccion[payload.field].find(todo => todo.id === payload.idMejora);
    let foto = mejora.fotosSIMCollection.find(todo => todo.id === payload.idFoto);

    console.log("Seteando imgPath: " + payload.imagePath + " - " + foto.id);
    foto.img_path = payload.imagePath;

    if (state.wrkFraccion.id === payload.idFraccion) {
      mejora = state.wrkFraccion[payload.field].find(todo => todo.id === payload.idMejora);
      foto = mejora.fotosSIMCollection.find(todo => todo.id === payload.idFoto);

      console.log("Seteando imgPath en temporal: " + payload.imagePath + " - " + foto.id);
      foto.img_path = payload.imagePath;
    }
  },
  SETMEJORADELETED(state, payload) {
    let fraccion = state.fracciones.find(todo => todo.id === payload.idFraccion);
    let index = fraccion[payload.field].findIndex(todo => todo.id === payload.idMejora);

    if (payload.logical !== null && !payload.logical) {
      //          Vue.delete(fraccion, payload.field, index);
      fraccion[payload.field].splice(index, 1);
    } else {
      fraccion[payload.field][index].deleted = Date.now();
    }

    index = state.wrkFraccion[payload.field].findIndex(todo => todo.id === payload.idMejora);

    if (payload.logical !== null && !payload.logical) {
      state.wrkFraccion[payload.field].splice(index, 1);
    } else {
      state.wrkFraccion[payload.field][index].deleted = Date.now();
    }
  },
  SETPAF(state, payload) {
    state.wrkFraccion.fraccionesWrapper.puntoAccesoGeoInfo = payload;

    let fraccion = state.fracciones.find(todo => todo.id === state.wrkFraccion.id);
    fraccion.fraccionesWrapper.puntoAccesoGeoInfo = payload;
  },
  MARKMEJORA(state, payload) {
    let fraccion = state.fracciones.find(todo => todo.id === payload.idFraccion);
    let index = fraccion[payload.field].findIndex(todo => todo.id === payload.idMejora);

    if (payload.toMove) {
      fraccion[payload.field][index].toMove = Date.now();
    } else {
      delete fraccion[payload.field][index].toMove;
    }

    index = state.wrkFraccion[payload.field].findIndex(todo => todo.id === payload.idMejora);

    if (payload.toMove) {
      state.wrkFraccion[payload.field][index].toMove = Date.now();
    } else {
      delete state.wrkFraccion[payload.field][index].toMove;
    }
  },
  MOVERMEJORA(state, payload) {
    let fraccion = state.fracciones.find(todo => todo.id === payload.fracOri);
    let index = fraccion[payload.field].findIndex(todo => todo.id === payload.idMejora);
    let mejora;

    if (index > -1) {
      console.log('Borrando mejora en fraccion origen...');
      mejora = fraccion[payload.field][index];
      fraccion[payload.field].splice(index, 1);
    }

    index = state.wrkFraccion[payload.field].findIndex(todo => todo.id === payload.idMejora);
    if (index > -1) {
      console.log('Borrando mejora en fraccion de trabajo...');
      state.wrkFraccion[payload.field].splice(index, 1);
    }

    if (mejora) {
      console.log('Agregando mejora en fraccion destino...');
      fraccion = state.fracciones.find(todo => todo.id === payload.fracDest);
      if (!fraccion[payload.field]) {
        fraccion[payload.field] = [];
      }
      fraccion[payload.field].push(mejora);
    }
  },
  UPDATEUSUARIO(state, payload) {
    let index = state.usuarios.findIndex(el => el.user === payload.user);

    if (index > -1) {
      state.usuarios[index] = payload;
    } else {
      state.usuarios.push(payload);
    }
  },
  LOGFOTOS(state, payload) {
    if (state.logFotos.length > 99) {
      state.logFotos.length = 0;
    }

    state.logFotos.push(payload);
  }
};
