<template>
  <v-card color="grey lighten-4" flat height="50vh" tile>
    <v-toolbar flat dense>
      <v-switch v-model="isEdition" hint="Editar?" persistent-hint @change="edit"></v-switch>
      <v-btn :disabled="selLength === 0 || (isEdition && type !== null)" icon>
        <v-icon color="blue" @click.stop="dialog = true">mdi-table-edit</v-icon>
      </v-btn>

      <v-menu
        style="z-index: 9000"
        left
        bottom
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn :disabled="!isEdition || (isEdition && type !== null)" icon v-on="on" v-bind="attrs">
            <v-icon color="blue">mdi-plus</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item
            v-for="(item) in this.types"
            :key="item.name"
            @click="create(item.name)"
          >
            <v-list-item-title>{{ item.text }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn :disabled="!isEdition || (isEdition && (type !== null || selLength === 0))" icon>
        <v-icon color="blue" @click.stop="remove(types[0].name)">mdi-minus</v-icon>
      </v-btn>
    </v-toolbar>
      <v-btn :disabled="!isEdition || (isEdition && (type !== null || selLength === 0))" icon>
        <v-icon color="blue" @click.stop="move()">mdi-move-resize-variant</v-icon>
      </v-btn>
    <v-data-table
      :headers="headers"
      :items="itemsCollection"
      v-model="selected"
      @item-selected="itemSelected($event)"
      item-key="id"
      :single-select="true"
      show-select
      dense
      height="40vh"
      fixed-header
      disable-pagination
      hide-default-footer
      class="elevation-1"
    >
      <template v-slot:item="{item, isSelected, select}">
        <tr v-bind:class="{'red lighten-3': item.modified && !item.deleted}">
          <td>
            <v-checkbox v-show="!item.deleted" :value="isSelected" @change="select($event)"></v-checkbox>
            <v-icon v-show="item.deleted && item.deleted > 0">mdi-delete</v-icon>
          </td>
          <td>{{getTypeText(item.type)}}</td>
          <td>{{(item.tiposDeEstadoSanitarioId) ? item.tiposDeEstadoSanitarioId.nombre : null}}</td>
          <td>{{item.superficie}}</td>
          <td>{{(item.tiposDeEspeciesMontesForestalesId) ? item.tiposDeEspeciesMontesForestalesId.nombre : null}}</td>
        </tr>
      </template>
    </v-data-table>
    <MonteForestal
      :data="data"
      :dialog="dialog && ((selLength > 0 && selected[0].type === types[0].name) || (isEdition && type === types[0].name))"
      :edit="isEdition" :isCancelling="isCancelling"
      @commit="commit"
      @cancel="cancel"
      @close="close"
    />
    <MonteFrutal
      :data="data"
      :dialog="dialog && ((selLength > 0 && selected[0].type === types[1].name) || (isEdition && type === types[1].name))"
      :edit="isEdition" :isCancelling="isCancelling"
      @commit="commit"
      @cancel="cancel"
      @close="close"
    />
    <MonteNativo
      :data="data"
      :dialog="dialog && ((selLength > 0 && selected[0].type === types[2].name) || (isEdition && type === types[2].name))"
      :edit="isEdition" :isCancelling="isCancelling"
      @commit="commit"
      @cancel="cancel"
      @close="close"
    />
    <MoverMejora :show="showFracciones" @close="closeShowFracciones"/>
  </v-card>
</template>

<script>
import "@mdi/font/css/materialdesignicons.css";
import MonteForestal from "./MonteForestal";
import MonteFrutal from "./MonteFrutal";
import MonteNativo from "./MonteNativo";
import mejoras from "../mixins/mejoras";

export default {
  name: "Montes",
  components: { MonteForestal, MonteFrutal, MonteNativo },

  mixins: [mejoras],
  data() {
    return {
      types: [
        { name: this.ModelTypes.MontesForestales.name, text: "Montes Forestales", colName: this.ModelTypes.MontesForestales.field, geom: "Point"},
        { name: this.ModelTypes.MontesFrutales.name, text: "Montes Frutales", colName: this.ModelTypes.MontesFrutales.field, geom: "Point"},
        { name: this.ModelTypes.MontesNativos.name, text: "Montes Nativos", colName: this.ModelTypes.MontesNativos.field, geom: "Point"}
      ],
      headers: [
        { text: "Tipo", value: "tiposDeMontesId.nombre", sortable: false },
        { text: "Estado", value: "tiposDeEstadoSanitarioId.nombre", sortable: false },
        { text: "Superficie", value: "superficie", sortable: false },
        { text: "Especie", value: "tiposDeEspeciesMontesForestalesId.nombre", sortable: false }
      ],
    };
  }
};
</script>
