export default {
    getConnectionStatus(state){
      return state.connectionStatus;
    },
    getDatosOperativosTimestamp(state){
      return state.datosOperativosTimestamp;
    },
    getFracciones(state) {
        return state.fracciones;
      },
      getTipoAlambrados(state) {
        return state.tiposDeAlambrados;
      },
      getEstadoAlambrados(state) {
        return state.estadoAlambrado;
      },
      getConservacionViviendas(state) {
        return state.tiposDeEstadosConservacionConstrucciones;
      },
      getTipoViviendas(state) {
        return state.tiposDeViviendaId;
      },
      getParedes(state) {
        return state.tiposDeMaterialesParedes;
      },
      getPisos(state) {
        return state.tiposDeMaterialesPisos;
      },
      getTechos(state) {
        return state.tiposDeMaterialesTechos;
      },
      getTipoM2Edificados(state) {
        return state.tiposDeM2Edificados;
      },
      getFraccion: (state) => (id) => {
        return state.fracciones.find(todo => todo.fraccion === id);
      },
      getTipoAlambrado: (state) => (id) => {
        return state.tiposDeAlambrados.find(todo => todo.id === id);
      },
      getEstadoAlambrado: (state) => (id) => {
        return state.estadoAlambrado.find(todo => todo.id === id);
      },
      getWrkFraccion: (state) => () => {
        return state.wrkFraccion;      
      },
      getWrkViviendas: (state) => () => {
        return state.wrkFraccion.viviendas;      
      },
      getWrkGalpones: (state) => () => {
        return state.wrkFraccion.galpones;      
      },
      getWrkStyleSize: (state) => () => {
        return state.wrkStyleSize;      
      },
        getModifiedViviendas: (state) => (id) => {
        let count = 0;
        let fraccion = !id ? state.wrkFraccion : state.fracciones.find(el => el.id === id);

        if (fraccion && fraccion.viviendas){
        fraccion.viviendas.forEach(element => {
          if (element.modified){
            count++;
          }  
        });
        }
  
        return count;
      },
      getModifiedAlambrados: (state) => (id) => {
        let count = 0;
        let fraccion = !id ? state.wrkFraccion : state.fracciones.find(el => el.id === id);
  
        if (fraccion && fraccion.alambrados){
        fraccion.alambrados.forEach(element => {
          if (element.modified){
            count++;
          }  
        });
        }
        if (fraccion && fraccion.guardaPatios){
        fraccion.guardaPatios.forEach(element => {
          if (element.modified){
            count++;
          }  
        });
        }
  
        return count;
      },
      getModifiedOtrosElem: (state) => (id) => {
        let count = 0;
        let fraccion = !id ? state.wrkFraccion : state.fracciones.find(el => el.id === id);
  
        if (fraccion && fraccion.bebederos){
        fraccion.bebederos.forEach(element => {
          if (element.modified){
            count++;
          }  
        });
        }
        if (fraccion && fraccion.bombas){
        fraccion.bombas.forEach(element => {
          if (element.modified){
            count++;
          }  
        });
        }
        if (fraccion && fraccion.caminos){
        fraccion.caminos.forEach(element => {
          if (element.modified){
            count++;
          }  
        });
        }
        if (fraccion && fraccion.ute){
        fraccion.ute.forEach(element => {
          if (element.modified){
            count++;
          }  
        });
        }
        if (fraccion && fraccion.molinos){
        fraccion.molinos.forEach(element => {
          if (element.modified){
            count++;
          }  
        });
        }
        if (fraccion && fraccion.sistemasRiego){
        fraccion.sistemasRiego.forEach(element => {
          if (element.modified){
            count++;
          }  
        });
        }
        if (fraccion && fraccion.tanquesAustralianos){
        fraccion.tanquesAustralianos.forEach(element => {
          if (element.modified){
            count++;
          }  
        });
        }
        if (fraccion && fraccion.tanques){
        fraccion.tanques.forEach(element => {
          if (element.modified){
            count++;
          }  
        });
        }
  
        return count;
      },
      getModifiedInstGanado: (state) => (id) => {
        let count = 0;
        let fraccion = !id ? state.wrkFraccion : state.fracciones.find(el => el.id === id);
  
        if (fraccion && fraccion.banios){
        fraccion.banios.forEach(element => {
          if (element.modified){
            count++;
          }  
        });
        }
        if (fraccion && fraccion.bretes){
        fraccion.bretes.forEach(element => {
          if (element.modified){
            count++;
          }  
        });
        }
        if (fraccion && fraccion.cepos){
        fraccion.cepos.forEach(element => {
          if (element.modified){
            count++;
          }  
        });
        }
        if (fraccion && fraccion.corrales){
        fraccion.corrales.forEach(element => {
          if (element.modified){
            count++;
          }  
        });
        }
        if (fraccion && fraccion.embarcadores){
        fraccion.embarcadores.forEach(element => {
          if (element.modified){
            count++;
          }  
        });
        }
        if (fraccion && fraccion.huevos){
        fraccion.huevos.forEach(element => {
          if (element.modified){
            count++;
          }  
        });
        }
        if (fraccion && fraccion.tubos){
        fraccion.tubos.forEach(element => {
          if (element.modified){
            count++;
          }  
        });
        }
        if (fraccion && fraccion.porteras){
        fraccion.porteras.forEach(element => {
          if (element.modified){
            count++;
          }  
        });
        }
  
        return count;
      },
      getModifiedMontes: (state) => (id) => {
        let count = 0;
        let fraccion = !id ? state.wrkFraccion : state.fracciones.find(el => el.id === id);
  
        if (fraccion && fraccion.montesForestales){
        fraccion.montesForestales.forEach(element => {
          if (element.modified){
            count++;
          }  
        });
        }
        if (fraccion && fraccion.montesFrutales){
        fraccion.montesFrutales.forEach(element => {
          if (element.modified){
            count++;
          }  
        });
        }
        if (fraccion && fraccion.montesNativos){
        fraccion.montesNativos.forEach(element => {
          if (element.modified){
            count++;
          }  
        });
        }
  
        return count;
      },
      getModifiedGalpones: (state) => (id) => {
        let count = 0;
        let fraccion = !id ? state.wrkFraccion : state.fracciones.find(el => el.id === id);
  
        if (fraccion && fraccion.galpones){
        fraccion.galpones.forEach(element => {
          if (element.modified){
            count++;
          }  
        });
        }
  
        return count;
      },
      getModifiedSalas: (state) => (id) => {
        let count = 0;
        let fraccion = !id ? state.wrkFraccion : state.fracciones.find(el => el.id === id);
  
        if (fraccion && fraccion.salasOrdene){
        fraccion.salasOrdene.forEach(element => {
          if (element.modified){
            count++;
          }  
        });
        }
        if (fraccion && fraccion.corralesEspera){
        fraccion.corralesEspera.forEach(element => {
          if (element.modified){
            count++;
          }  
        });
        }
        if (fraccion && fraccion.salasFrio){
        fraccion.salasFrio.forEach(element => {
          if (element.modified){
            count++;
          }  
        });
        }
        if (fraccion && fraccion.decantadores){
        fraccion.decantadores.forEach(element => {
          if (element.modified){
            count++;
          }  
        });
        }
  
        return count;
      },
      getModifiedPozos: (state) => (id) => {
        let count = 0;
        let fraccion = !id ? state.wrkFraccion : state.fracciones.find(el => el.id === id);
  
        if (fraccion && fraccion.pozos){
        fraccion.pozos.forEach(element => {
          if (element.modified){
            count++;
          }  
        });
        }
  
        return count;
      },
      getModifiedGallineros: (state) => (id) => {
        let count = 0;
        let fraccion = !id ? state.wrkFraccion : state.fracciones.find(el => el.id === id);
  
        if (fraccion && fraccion.gallineros){
        fraccion.gallineros.forEach(element => {
          if (element.modified){
            count++;
          }  
        });
        }
  
        return count;
      },
      getModifiedOtrasMejoras: (state) => (id) => {
        let count = 0;
        let fraccion = !id ? state.wrkFraccion : state.fracciones.find(el => el.id === id);
  
        if (fraccion && fraccion.otrasMejoras){
        fraccion.otrasMejoras.forEach(element => {
          if (element.modified){
            count++;
          }  
        });
        }
  
        return count;
      },
      getModifiedAgua: (state) => (id) => {
        let count = 0;
        let fraccion = !id ? state.wrkFraccion : state.fracciones.find(el => el.id === id);
  
        if (fraccion && fraccion.aguadas){
        fraccion.aguadas.forEach(element => {
          if (element.modified){
            count++;
          }  
        });
        }
  
        if (fraccion && fraccion.polders){
          fraccion.polders.forEach(element => {
            if (element.modified){
              count++;
            }  
          });
          }
  
        return count;
      },
      getUltActViviendas: (state) => (id) => {
        let count = 0;
        let fraccion = !id ? state.wrkFraccion : state.fracciones.find(el => el.id === id);

        if (fraccion && fraccion.viviendas){
        fraccion.viviendas.forEach(element => {
          if (element.modified && element.modified > count){
            count = element.modified;
          }  
        });
        }
  
        return count;
      },
      getUltActAlambrados: (state) => (id) => {
        let count = 0;
        let fraccion = !id ? state.wrkFraccion : state.fracciones.find(el => el.id === id);
  
        if (fraccion && fraccion.alambrados){
        fraccion.alambrados.forEach(element => {
          if (element.modified && element.modified > count){
            count = element.modified;
          }  
        });
        }
        if (fraccion && fraccion.guardaPatios){
        fraccion.guardaPatios.forEach(element => {
          if (element.modified && element.modified > count){
            count = element.modified;
          }  
        });
        }
  
        return count;
      },
      getUltActOtrosElem: (state) => (id) => {
        let count = 0;
        let fraccion = !id ? state.wrkFraccion : state.fracciones.find(el => el.id === id);
  
        if (fraccion && fraccion.bebederos){
        fraccion.bebederos.forEach(element => {
          if (element.modified && element.modified > count){
            count = element.modified;
          }  
        });
        }
        if (fraccion && fraccion.bombas){
        fraccion.bombas.forEach(element => {
          if (element.modified && element.modified > count){
            count = element.modified;
          }  
        });
        }
        if (fraccion && fraccion.caminos){
        fraccion.caminos.forEach(element => {
          if (element.modified && element.modified > count){
            count = element.modified;
          }  
        });
        }
        if (fraccion && fraccion.ute){
        fraccion.ute.forEach(element => {
          if (element.modified && element.modified > count){
            count = element.modified;
          }  
        });
        }
        if (fraccion && fraccion.molinos){
        fraccion.molinos.forEach(element => {
          if (element.modified && element.modified > count){
            count = element.modified;
          }  
        });
        }
        if (fraccion && fraccion.sistemasRiego){
        fraccion.sistemasRiego.forEach(element => {
          if (element.modified && element.modified > count){
            count = element.modified;
          }  
        });
        }
        if (fraccion && fraccion.tanquesAustralianos){
        fraccion.tanquesAustralianos.forEach(element => {
          if (element.modified && element.modified > count){
            count = element.modified;
          }  
        });
        }
        if (fraccion && fraccion.tanques){
        fraccion.tanques.forEach(element => {
          if (element.modified && element.modified > count){
            count = element.modified;
          }  
        });
        }
  
        return count;
      },
      getUltActInstGanado: (state) => (id) => {
        let count = 0;
        let fraccion = !id ? state.wrkFraccion : state.fracciones.find(el => el.id === id);
  
        if (fraccion && fraccion.banios){
        fraccion.banios.forEach(element => {
          if (element.modified && element.modified > count){
            count = element.modified;
          }  
        });
        }
        if (fraccion && fraccion.bretes){
        fraccion.bretes.forEach(element => {
          if (element.modified && element.modified > count){
            count = element.modified;
          }  
        });
        }
        if (fraccion && fraccion.cepos){
        fraccion.cepos.forEach(element => {
          if (element.modified && element.modified > count){
            count = element.modified;
          }  
        });
        }
        if (fraccion && fraccion.corrales){
        fraccion.corrales.forEach(element => {
          if (element.modified && element.modified > count){
            count = element.modified;
          }  
        });
        }
        if (fraccion && fraccion.embarcadores){
        fraccion.embarcadores.forEach(element => {
          if (element.modified && element.modified > count){
            count = element.modified;
          }  
        });
        }
        if (fraccion && fraccion.huevos){
        fraccion.huevos.forEach(element => {
          if (element.modified && element.modified > count){
            count = element.modified;
          }  
        });
        }
        if (fraccion && fraccion.tubos){
        fraccion.tubos.forEach(element => {
          if (element.modified && element.modified > count){
            count = element.modified;
          }  
        });
        }
        if (fraccion && fraccion.porteras){
        fraccion.porteras.forEach(element => {
          if (element.modified && element.modified > count){
            count = element.modified;
          }  
        });
        }
  
        return count;
      },
      getUltActMontes: (state) => (id) => {
        let count = 0;
        let fraccion = !id ? state.wrkFraccion : state.fracciones.find(el => el.id === id);
  
        if (fraccion && fraccion.montesForestales){
        fraccion.montesForestales.forEach(element => {
          if (element.modified && element.modified > count){
            count = element.modified;
          }  
        });
        }
        if (fraccion && fraccion.montesFrutales){
        fraccion.montesFrutales.forEach(element => {
          if (element.modified && element.modified > count){
            count = element.modified;
          }  
        });
        }
        if (fraccion && fraccion.montesNativos){
        fraccion.montesNativos.forEach(element => {
          if (element.modified && element.modified > count){
            count = element.modified;
          }  
        });
        }
  
        return count;
      },
      getUltActGalpones: (state) => (id) => {
        let count = 0;
        let fraccion = !id ? state.wrkFraccion : state.fracciones.find(el => el.id === id);
  
        if (fraccion && fraccion.galpones){
        fraccion.galpones.forEach(element => {
          if (element.modified && element.modified > count){
            count = element.modified;
          }  
        });
        }
  
        return count;
      },
      getUltActSalas: (state) => (id) => {
        let count = 0;
        let fraccion = !id ? state.wrkFraccion : state.fracciones.find(el => el.id === id);
  
        if (fraccion && fraccion.salasOrdene){
        fraccion.salasOrdene.forEach(element => {
          if (element.modified && element.modified > count){
            count = element.modified;
          }  
        });
        }
        if (fraccion && fraccion.corralesEspera){
        fraccion.corralesEspera.forEach(element => {
          if (element.modified && element.modified > count){
            count = element.modified;
          }  
        });
        }
        if (fraccion && fraccion.salasFrio){
        fraccion.salasFrio.forEach(element => {
          if (element.modified && element.modified > count){
            count = element.modified;
          }  
        });
        }
        if (fraccion && fraccion.decantadores){
        fraccion.decantadores.forEach(element => {
          if (element.modified && element.modified > count){
            count = element.modified;
          }  
        });
        }
  
        return count;
      },
      getUltActPozos: (state) => (id) => {
        let count = 0;
        let fraccion = !id ? state.wrkFraccion : state.fracciones.find(el => el.id === id);
  
        if (fraccion && fraccion.pozos){
        fraccion.pozos.forEach(element => {
          if (element.modified && element.modified > count){
            count = element.modified;
          }  
        });
        }
  
        if (fraccion && fraccion.pozosBalde){
          fraccion.pozosBalde.forEach(element => {
            if (element.modified && element.modified > count){
              count = element.modified;
            }  
          });
          }

          return count;
      },
      getUltActGallineros: (state) => (id) => {
        let count = 0;
        let fraccion = !id ? state.wrkFraccion : state.fracciones.find(el => el.id === id);
  
        if (fraccion && fraccion.gallineros){
        fraccion.gallineros.forEach(element => {
          if (element.modified && element.modified > count){
            count = element.modified;
          }  
        });
        }
  
        return count;
      },
      getUltActOtrasMejoras: (state) => (id) => {
        let count = 0;
        let fraccion = !id ? state.wrkFraccion : state.fracciones.find(el => el.id === id);
  
        if (fraccion && fraccion.otrasMejoras){
        fraccion.otrasMejoras.forEach(element => {
          if (element.modified && element.modified > count){
            count = element.modified;
          }  
        });
        }
  
        return count;
      },
      getUltActAgua: (state) => (id) => {
        let count = 0;
        let fraccion = !id ? state.wrkFraccion : state.fracciones.find(el => el.id === id);
  
        if (fraccion && fraccion.aguadas){
        fraccion.aguadas.forEach(element => {
          if (element.modified && element.modified > count){
            count = element.modified;
          }  
        });
        }
  
        return count;
      },
      getUsuario: (state) => (user) => {
        return state.usuarios.find(el => el.user === user);
      }
};