let Constants = {
  install(Vue) {
    Vue.prototype.General = {tmpId: 'tmp-'};
    Vue.prototype.SyleSizes = {small: 4, normal: 8, big: 12};
    Vue.prototype.ModelTypes = { // tipos del modelo de la aplicacion móvil
      Viviendas: {name: 'Viviendas', field: "viviendas"},
      Alambrados: {name: 'Alambrados', field: "alambrados"},
      Galpones: {name: 'Galpones', field: "galpones"},
      SalasOrdene: {name: 'SalasDeOrdenie', field: "salasOrdene"},
      GuardaPatios: {name: 'GuardaPatios', field: "guardaPatios"},
      Decantadores: {name: 'Decantadores', field: "decantadores"},
      SistemasRiego: {name: 'SistemasDeRiego', field: "sistemasRiego"},
      Caminos: {name: 'Caminos', field: "caminos"},
      Pozos: {name: 'Pozos', field: "pozos"},
      PozosBalde: {name: 'PozoDeBalde', field: "pozosBalde"},
      Molinos: {name: 'MolinosDeAgua', field: "molinos"},
      Bombas: {name: 'BombasSumergibles', field: "bombas"},
      BebederosComederos: {name: 'BebederosComederos', field: "bebederos"},
      ConexionesUTE: {name: 'ConexionesUTE', field: "ute"},
      TanquesAustralianos: {name: 'TanquesAustralianos', field: "tanquesAustralianos"},
      Tanques: {name: 'Tanques', field: "tanques"},
      Tubos: {name: 'Tubos', field: "tubos"},
      GallinerosChiqueros: {name: 'GallinerosChiqueros', field: "gallineros"},
      Corrales: {name: 'Corrales', field: "corrales"},
      CorralesEspera: {name: 'CorralesDeEspera', field: "corralesEspera"},
      Cepos: {name: 'Cepos', field: "cepos"},
      Banios: {name: 'BaniosParaGanado', field: "banios"},
      Embarcaderos: {name: 'Embarcaderos', field: "embarcaderos"},
      Huevos: {name: 'Huevos', field: "huevos"},
      Bretes: {name: 'Bretes', field: "bretes"},
      MontesNativos: {name: 'MontesNativos', field: "montesNativos"},
      MontesForestales: {name: 'MontesForestales', field: "montesForestales"},
      MontesFrutales: {name: 'MontesFrutales', field: "montesFrutales"},
      OtrasMejoras: {name: 'OtrasMejoras', field: "otrasMejoras"},
      Porteras: {name: 'Porteras', field: "porteras"},
      Aguadas: {name: 'ReservasDeAgua', field: "aguadas"},
      Polders: {name: 'Polders', field: "polders"},
      SalasFrio: {name: 'SalasFrio', field: "salasFrio"}
      };
      Vue.prototype.TypesFromWS = [ // tipos del WS que no se traducen directamente a tipos de la app
        {name: 'Construccion', field: 'tiposDeConstruccionId', subTypes: [{id: 3, type: 'SalasFrio'}]},
        {name: 'MejorasConEstado', field: 'tiposDeMejorasId', subTypes: [{id: 61, type: 'OtrasMejoras'}]}
      ];
      Vue.prototype.$findTypeByName = function(name){
        let res = undefined;

        for (const key of Object.keys(this.ModelTypes)) {
          if (this.ModelTypes[key].name === name) {
            res = key;
            break;
          }
        }
        
        return this.ModelTypes[res];
      };
      Vue.prototype.$setTiposDeConstruccion = function(mejora){
        let res;

        switch(mejora.type){
          case this.ModelTypes.Viviendas.name:
            res = 'VIV';
            break;
            /*
          case this.ModelTypes.GallinerosChiqueros.name:
            if (mejora.tiposDeGallineroChiqueroId.codigo === 'CHI'){
              res = 'POR';
            }
            break;
            */
          case this.ModelTypes.SalasFrio.name:
            res = 'QUE';
            break;
          case this.ModelTypes.Galpones.name:
            res = 'GAL';
            break;
          case this.ModelTypes.SalasOrdene.name:
            res = 'SAL';
            break;
          default:
            break;
        }

        if (res){
          res = this.$store.state.tiposDeConstruccion.find((val) => val.codigo === res);
        }

        return res;
      };
      Vue.prototype.$setType = function(mejora){
        let res;

        switch(mejora.type){
          case this.ModelTypes.Viviendas.name:
            if (mejora.tiposDeViviendaId.codigo === 'PRI'){
              res = 'VPR';
            } else if (mejora.tiposDeViviendaId.codigo === 'SEC'){
              res = 'VSE';
            } else if (mejora.tiposDeViviendaId.codigo === 'PER'){
              res = 'VPE';
            } else if (mejora.tiposDeViviendaId.codigo === 'OTR'){
              res = 'OVI';
            }
            break;
          case this.ModelTypes.Alambrados.name:
            if (mejora.tiposDeAlambradosId.codigo === 'INT'){
              res = 'AIN';
            } else if (mejora.tiposDeAlambradosId.codigo === 'PER'){
              res = 'APE';
            }
            break;
          case this.ModelTypes.Galpones.name:
            if (mejora.tiposDeGalponId.codigo === 'PRI'){
              res = 'GPR';
            } else if (mejora.tiposDeGalponId.codigo === 'HER'){
              res = 'GHE';
            } else if (mejora.tiposDeGalponId.codigo === 'ENS'){
              res = 'GEN';
            } else if (mejora.tiposDeGalponId.codigo === 'TIN'){
              res = 'GTI';
            } else if (mejora.tiposDeGalponId.codigo === 'SEC'){
              res = 'GSE';
            } else if (mejora.tiposDeGalponId.codigo === 'ACR'){
              res = 'GAC';
            } else if (mejora.tiposDeGalponId.codigo === 'APP'){
              res = 'GPP';
            } else if (mejora.tiposDeGalponId.codigo === 'APJ'){
              res = 'GPJ';
            }
            break;
          case this.ModelTypes.SalasOrdene.name:
            res = 'TGO';
            break;
          case this.ModelTypes.GuardaPatios.name:
              res = 'GPA';
            break;
          case this.ModelTypes.Decantadores.name:
            res = 'TPD';
            break;
          case this.ModelTypes.SistemasRiego.name:
            res = 'SDR';
            break;
          case this.ModelTypes.Caminos.name:
            res = 'CAM';
            break;
          case this.ModelTypes.Pozos.name:
            if (mejora.tiposDePozoId.codigo === 'PEN'){
              res = 'PPS';
            } else if (mejora.tiposDePozoId.codigo === 'ALJ'){
              res = 'PDA';
            }
            break;
            case this.ModelTypes.PozosBalde.name:
              res = 'PDB';
              break;
            case this.ModelTypes.Molinos.name:
            res = 'MDA';
            break;
          case this.ModelTypes.Bombas.name:
            res = 'BSU';
            break;
          case this.ModelTypes.BebederosComederos.name:
            if (mejora.tiposDeAlimentadoresId.codigo === 'BEB'){
              res = 'BEB';
            } else if (mejora.tiposDeAlimentadoresId.codigo === 'COM'){
              res = 'COM';
            }
            break;
          case this.ModelTypes.ConexionesUTE.name:
            res = 'ELE';
            break;
          case this.ModelTypes.TanquesAustralianos.name:
            res = 'TAU';
            break;
          case this.ModelTypes.Tanques.name:
            if (mejora.tiposDeTanqueId.codigo === 'ELE'){
              res = 'TEL';
            } else if (mejora.tiposDeTanqueId.codigo === 'DOM'){
              res = 'TDO';
            }
            break;
          case this.ModelTypes.Tubos.name:
            res = 'TUV';
            break;
          case this.ModelTypes.GallinerosChiqueros.name:
            if (mejora.tiposDeGallineroChiqueroId.codigo === 'GAL'){
              res = 'GAL';
            } else if (mejora.tiposDeGallineroChiqueroId.codigo === 'CHI'){
              res = 'CHI';
            }
            break;
          case this.ModelTypes.Corrales.name:
            if (mejora.tiposDeCorralesId.codigo === 'CDE'){
              res = 'CEN';
            } else if (mejora.tiposDeCorralesId.codigo === 'CDS'){
              res = 'CSA';
            }
            break;
          case this.ModelTypes.CorralesEspera.name:
            res = 'TCE';
            break;
          case this.ModelTypes.Cepos.name:
            res = 'CEP';
            break;
          case this.ModelTypes.Banios.name:
            if (mejora.tiposDeUsoBanioParaGanadoId.codigo === 'BVA'){
              res = 'BVA';
            } else if (mejora.tiposDeUsoBanioParaGanadoId.codigo === 'BLA'){
              res = 'BLA';
            }
            break;
          case this.ModelTypes.Embarcaderos.name:
            if (mejora.tiposDeUsoEmbarcaderoId.codigo === 'EVA'){
              res = 'EVA';
            } else if (mejora.tiposDeUsoEmbarcaderoId.codigo === 'ELA'){
              res = 'ELA';
            } else if (mejora.tiposDeUsoEmbarcaderoId.codigo === 'EPO'){
              res = 'EPO';
            }
            break;
          case this.ModelTypes.Huevos.name:
            res = 'HUE';
            break;
          case this.ModelTypes.Bretes.name:
            if (mejora.tiposDeBretesId.codigo === 'BDE'){
              res = 'BEN';
            } else if (mejora.tiposDeBretesId.codigo === 'BDS'){
              res = 'BSA';
            } else if (mejora.tiposDeBretesId.codigo === 'BDA'){
              res = 'BAG';
            }
            break;
          case this.ModelTypes.MontesNativos.name:
            res = 'MNA';
            break;
          case this.ModelTypes.MontesForestales.name:
            if (mejora.tiposDeMontesId.codigo === 'DAB'){
              res = 'MAS';
            } else if (mejora.tiposDeMontesId.codigo === 'DRE'){
              res = 'MRE';
            } else if (mejora.tiposDeMontesId.codigo === 'COR'){
              res = 'MCO';
            }
            break;
          case this.ModelTypes.MontesFrutales.name:
            res = 'MOT';
            break;
          case this.ModelTypes.Porteras.name:
            if (mejora.tiposDeUsoPorteraId.codigo === 'PVA'){
              res = 'PVA';
            } else if (mejora.tiposDeUsoPorteraId.codigo === 'PLA'){
              res = 'POV';
            }
          break;
          case this.ModelTypes.Aguadas.name:
            if (mejora.tiposDeReservaDeAguaId.codigo === 'REP'){
              res = 'ARE';
            } else if (mejora.tiposDeReservaDeAguaId.codigo === 'TAJ'){
              res = 'ATA';
            }
            break;
          case this.ModelTypes.Polders.name:
              res = 'APO';
              break;
          case this.ModelTypes.SalasFrio.name:
            res = 'SFQ';
            break;
          case this.ModelTypes.OtrasMejoras.name:
              res = 'OTR';
              break;
            default:
            res = '';
            break;  
        }

        if (!res){
          console.log('Mejora con tipo no reconocido o sin tipo! (' + mejora.type + ")");
          throw new TypeError('Mejora con tipo no reconocido o sin tipo!', mejora.type);
        }

//        res = this.$store.state.tiposDeMejoras.find((val) => val.activo && val.codigo === res);
        res = this.$store.state.tiposDeMejoras.find((val) => val.codigo === res);

        return res;
      };
  }
};
  
  export default Constants;