export default {
  connectionStatus: navigator.onLine,
  wrkFraccion: null,
  wrkState: {
    edit: null,
    refresh: null,
    selected: null
  },
  wrkStyleSize: "normal",
  fracciones: [],
  usuarios: [],
  logFotos: []
}
