<template>
  <v-row justify="center">
    <v-dialog
      ref="salaDialog"
      v-if="data"
      class="over"
      :fullscreen="true"
      persistent
      v-model="show"
      width="500"
    >
      <!-- v-if="data" -->
      <v-card>
        <v-card-title>
          <span class="headline">Corral de Espera</span>
        </v-card-title>
        <v-card-text>
          <v-form v-model="valid" ref="form">
            <v-container>
              <v-row>
                <v-textarea
                  :disabled="!edit"
                  v-model="data.descripcion"
                  label="Descripcion"
                ></v-textarea>
              </v-row>
              <v-row>
                <v-col cols="2">
                  <v-select 
                    :disabled="!edit"
                    label="*En construccion?"
                    v-model="data.enConstruccion"
                    :items="boolItems" 
                    :menu-props="{closeOnContentClick: true}"
                    :rules="[requiredBool('en construccion')]"
                  >
                    <template v-slot:prepend-item>
                      <v-list-item ripple @click="data.enConstruccion = null">
                        <v-list-item-action>
                          <v-icon>mdi-close-circle-outline</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title> Sin dato </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider class="mt-2"></v-divider>
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="2">
                  <v-select 
                    :disabled="!edit"
                    label="Propiedad INC?"
                    v-model="data.propiedadINC"
                    :menu-props="{closeOnContentClick: true}"
                    :items="boolItems" 
                  >
                    <template v-slot:prepend-item>
                      <v-list-item ripple @click="data.propiedadINC = null">
                        <v-list-item-action>
                          <v-icon>mdi-close-circle-outline</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title> Sin dato </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider class="mt-2"></v-divider>
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="2">
                  <v-text-field
                    :disabled="!edit"
                    v-model="data.porcentajePropiedadDelColono"
                    label="Derecho indemnizatorio(%)"
                    type="number"
                    onkeydown="return event.keyCode !== 69 && event.keyCode !== 188 && event.keyCode !== 189 && event.keyCode !== 190"
                    :rules="[num_range('Derecho indem(%)', 0, 100)]"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <!-- v-col cols="2">
                <v-select return-object
                  :disabled="!edit"
                  label="Tipo corral espera"
                  required
                  v-model="data.tiposDeMejorasId"
                  item-text="nombre"
                  :items="$store.state.tiposDeCorrales"
                  :menu-props="{closeOnContentClick: true}"
                  :rules="[required('tipo corral')]"
                >
                    <template v-slot:prepend-item>
                      <v-list-item ripple @click="data.tiposDeMejorasId = null">
                        <v-list-item-action>
                          <v-icon>mdi-close-circle-outline</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title> Sin dato </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider class="mt-2"></v-divider>
                    </template>
                </v-select>
                </v-col -->
                <v-col cols="2">
                  <v-select return-object
                    :disabled="!edit"
                    label="Estado conservacion"
                    v-model="data.tiposDeEstadosConservacionMejorasId"
                  item-text="nombre"
                    :items="$store.state.tiposDeEstadosConservacionMejoras"
                    :menu-props="{closeOnContentClick: true}"
                    :rules="[required('estado conservacion')]"
                  >
                    <template v-slot:prepend-item>
                      <v-list-item ripple @click="data.tiposDeEstadosConservacionMejorasId = null">
                        <v-list-item-action>
                          <v-icon>mdi-close-circle-outline</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title> Sin dato </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider class="mt-2"></v-divider>
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="2">
                <v-text-field
                  :disabled="!edit"
                  label="*Superficie"
                  v-model="data.superficie"
                  type="number"
                    onkeydown="return event.keyCode !== 69 && event.keyCode !== 188 && event.keyCode !== 189 && event.keyCode !== 190"
                  :rules="[required('superficie'), positive('superficie')]"
                ></v-text-field>
                </v-col>
                <v-col cols="2">
                  <v-select 
                    :disabled="!edit"
                    label="*Mevir?"
                    v-model="data.mevir"
                    :items="boolItems" 
                    :menu-props="{closeOnContentClick: true}"
                    :rules="[requiredBool('mevir')]"
                  >
                    <template v-slot:prepend-item>
                      <v-list-item ripple @click="data.mevir = null">
                        <v-list-item-action>
                          <v-icon>mdi-close-circle-outline</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title> Sin dato </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider class="mt-2"></v-divider>
                    </template>
                  </v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn v-if="edit" color="primary" :disabled="!valid || this.isCancelling" @click="commit">Guardar</v-btn>
          <v-btn v-if="edit" @click="cancel" :disabled="this.isCancelling">Cancelar</v-btn>
          <v-btn v-if="!edit" @click="close" :disabled="this.isCancelling">Cerrar</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import "@mdi/font/css/materialdesignicons.css";
import validations from "../mixins/validations.js";

export default {
  name: "CorralEspera",
  props: ["data", "dialog", "edit", "isCancelling"],
  mixins: [validations],
  mounted() {},
  data() {
    return {
      valid: false,
      boolItems: [{text: 'Si', value: true}, {text: 'No', value: false}]
    };
  },
  methods: {
    close: function () {
      this.$emit("close");
    },
    commit: function () {
      this.$emit("commit");
    },
    cancel: function () {
      this.$emit("cancel");
    },
  },
  computed: {
    show: {
      get() {
        return this.dialog;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
  },
  watch: {
    show(newSel) {
      if (newSel) {
        this.$nextTick(function () {
          this.$refs.salaDialog.stackMinZIndex = 9000;
          this.$refs.form.validate();
        });
      }
    },
  },
};
</script>

<style scoped>
.over {
  z-index: 9000 !important;
}
</style>
