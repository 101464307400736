<template>
  <v-row justify="center">
    <v-dialog
      v-if="data"
      ref="patioDialog"
      class="over"
      :fullscreen="true"
      persistent
      v-model="show"
      width="400"
    >
      <v-card>
        <v-card-title>
          <span class="headline">GuardaPatio</span>
        </v-card-title>
        <v-card-text>
          <v-form v-model="valid" ref="form">
            <v-container>
              <v-row>
                <v-textarea
                  :disabled="!edit"
                  v-model="data.descripcion"
                  label="Descripcion"
                ></v-textarea>
              </v-row>
              <v-row>
                <v-col cols="2">
                  <v-select 
                    :disabled="!edit"
                    label="*En construccion?"
                    v-model="data.enConstruccion"
                    :items="boolItems" 
                    :menu-props="{closeOnContentClick: true}"
                    :rules="[requiredBool('en construccion')]"
                  >
                    <template v-slot:prepend-item>
                      <v-list-item ripple @click="data.enConstruccion = null">
                        <v-list-item-action>
                          <v-icon>mdi-close-circle-outline</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title> Sin dato </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider class="mt-2"></v-divider>
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="2">
                  <v-select 
                    :disabled="!edit"
                    label="Propiedad INC?"
                    v-model="data.propiedadINC"
                    :menu-props="{closeOnContentClick: true}"
                    :items="boolItems" 
                  >
                    <template v-slot:prepend-item>
                      <v-list-item ripple @click="data.propiedadINC = null">
                        <v-list-item-action>
                          <v-icon>mdi-close-circle-outline</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title> Sin dato </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider class="mt-2"></v-divider>
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="2">
                  <v-text-field
                    :disabled="!edit"
                    v-model="data.porcentajePropiedadDelColono"
                    label="Derecho indemnizatorio(%)"
                    type="number"
                    onkeydown="return event.keyCode !== 69 && event.keyCode !== 188 && event.keyCode !== 189 && event.keyCode !== 190"
                    :rules="[num_range('Derecho indem(%)', 0, 100)]"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="3">
                  <v-select return-object
                    :disabled="!edit"
                    label="*Estado"
                    v-model="data.tiposDeEstadosConservacionAlambradosId"
                    :rules="[required('estado')]"
                    :menu-props="{closeOnContentClick: true}"
                  item-text="nombre"
                    :items="$store.state.tiposDeEstadosConservacionAlambrados"
                  >
                    <template v-slot:prepend-item>
                      <v-list-item ripple @click="data.tiposDeEstadosConservacionAlambradosId = null">
                        <v-list-item-action>
                          <v-icon>mdi-close-circle-outline</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title> Sin dato </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider class="mt-2"></v-divider>
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="3">
                  <v-select return-object
                    :disabled="!edit"
                    label="*Material"
                    v-model="data.tiposDeMaterialesGuardaPatiosId"
                    :rules="[required('material')]"
                    :menu-props="{closeOnContentClick: true}"
                    required
                    item-text="nombre"
                    :items="$store.state.tiposDeMaterialesGuardaPatios"
                    @change="validateForm"
                  >
                    <template v-slot:prepend-item>
                      <v-list-item ripple @click="data.tiposDeMaterialesGuardaPatiosId = null">
                        <v-list-item-action>
                          <v-icon>mdi-close-circle-outline</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title> Sin dato </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider class="mt-2"></v-divider>
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="2">
                  <v-text-field
                    :disabled="!edit || (edit && (!data.tiposDeMaterialesGuardaPatiosId || data.tiposDeMaterialesGuardaPatiosId.id !== 2))"
                    v-model="data.cantidadHilos"
                    label="*Cantidad hilos"
                    type="number"
                    onkeydown="return event.keyCode !== 69 && event.keyCode !== 188 && event.keyCode !== 189 && event.keyCode !== 190"
                    :rules="[requiredAlambrado('hilos'), positiveAndInteger('hilos')]"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="2">
                  <v-text-field
                    :disabled="!edit"
                    v-model="data.largo"
                    :hint="largoHint"
                    label="*Largo"
                    type="number"
                    onkeydown="return event.keyCode !== 69 && event.keyCode !== 188 && event.keyCode !== 189 && event.keyCode !== 190"
                    :rules="[required('largo'), positive('largo')]"
                  ></v-text-field>
                </v-col>
                <!-- v-col cols="1">
                  <v-btn v-if="edit" @click="setDistance">
                    <v-icon>mdi-calculator</v-icon>
                  </v-btn>
                </v-col -->
              </v-row>
              <v-row>
                <v-col cols="2">
                  <v-text-field
                    :disabled="!edit"
                    v-model="data.edad"
                    :hint="edadHint"
                    label="Edad"
                    type="number"
                    onkeydown="return event.keyCode !== 69 && event.keyCode !== 188 && event.keyCode !== 189 && event.keyCode !== 190"
                    :rules="[positive('edad')]"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn v-if="edit" color="primary" :disabled="!valid || this.isCancelling" @click="commit">Guardar</v-btn>
          <v-btn v-if="edit" @click="cancel" :disabled="this.isCancelling">Cancelar</v-btn>
          <v-btn v-if="!edit" @click="close" :disabled="this.isCancelling">Cerrar</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import "@mdi/font/css/materialdesignicons.css";
import validations from "../mixins/validations.js";

export default {
  name: "GuardaPatio",
  props: ["map", "data", "dialog", "edit", "isCancelling"],
  mixins: [validations],
  mounted() {},
  data() {
    return {
      valid: false,
      largoHint: "",
      edadHint: "",
      boolItems: [{text: 'Si', value: true}, {text: 'No', value: false}]
    };
  },
  methods: {
    close: function () {
      this.$emit("close");
    },
    commit: function () {
      this.$emit("commit");
    },
    cancel: function () {
      this.$emit("cancel");
    },
    /*
    setDistance: function () {
      if (!this.data.geoInfo || this.data.geoInfo.length === 0) {
        this.$toasted.info("El guarda patio no tiene geometria...", {
          duration: 1500,
        });
      } else {
        let line = new L.Polyline(this.data.geoInfo);
        var latLngs = line.getLatLngs(),
          totalDist = 0,
          ll1,
          ll2,
          dist;

        for (var i = 1, len = latLngs.length; i < len; i++) {
          ll1 = latLngs[i - 1];
          ll2 = latLngs[i % len];
          dist = ll1.distanceTo(ll2);
          totalDist += dist;
        }

        if (totalDist > 0){
          this.data.largo = Math.round(totalDist);
        }
      }
    },
    */
    requiredAlambrado(propertyName){
      if (!this.edit) return true;

      if (this.data.tipoDeMaterialesGuardaPatioId && this.data.tiposDeMaterialesGuardaPatiosId.id === 2){
        return !this.data.cantidadHilos ? `${propertyName} es requerido con material alambre` : true;
      } else {
        return true;
      }
    }
  },
  computed: {
    largo() {
      return (this.data) ? this.data.largo : null;
    },
    edad() {
      return (this.data) ? this.data.edad : null;
    },
    show: {
      get() {
        return this.dialog;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
  },
  watch: {
    show(newSel) {
      if (newSel) {
        this.$nextTick(function () {
          this.$refs.patioDialog.stackMinZIndex = 9000;
          this.$refs.form.validate();
        });
      }
    },
    largo(newSel){
      if (newSel > 99){
        this.largoHint = "atencion: largo mayor a 99...";
      } else {
        this.largoHint = "";
      }
    },
    edad(newSel){
      if (newSel > 99){
        this.edadHint = "atencion: edad mayor a 99...";
      } else {
        this.edadHint = "";
      }
    }
  },
};
</script>

<style scoped>
.over {
  z-index: 9000 !important;
}
</style>
