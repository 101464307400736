/* eslint-disable no-console */

import { register } from 'register-service-worker'

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready () {
      console.log(
        'Cache de la app listo'
      )
    },
    registered () {
      console.log('Se registro el WPA service worker')
    },
    cached () {
      console.log('El contenido se agrego a cache para uso offline')
    },
    updatefound () {
      console.log('Se encontro nuevo contenido')
    },
    updated (registration) {
      console.log('Se actualizo la aplicacion, es necesario reiniciar o refrescar');
      document.dispatchEvent(
        new CustomEvent('swUpdated', { detail: registration })
      )
    },
    offline () {
      console.log('Sin conexion, corriendo offline')
    },
    error (error) {
      console.error('Error registrando el WPA service worker: ', error)
    }
  })
}
