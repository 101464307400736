<template>
  <div></div>
</template>

<script>
import * as L from "leaflet";
import { mapState } from "vuex";

export default {
  name: "GeoFraccion",
  props: ["map"],
  mounted() {
//    console.log("montando");

    this.fraccionPOIButton = L.easyButton({
      position: "topright",
      states: [
        {
          stateName: "begin",
          title: "Punto de entrada fraccion",
          onClick: this.click,
          icon: "fa-hand-o-up",
        },
      ],
    });
    this.fraccionPOIButton.addTo(this.map);

    this.map.on("pm:create", this.edit);
  },
  beforeDestroy() {
    this.map.off("pm:create", this.edit);
    if (this.fraccionPOIButton){
      this.fraccionPOIButton.removeFrom(this.map);
      this.fraccionPOIButton = null;
    }
    if (this.fraccionLayer){
      this.fraccionLayer.remove();
      this.fraccionLayer = null;
    }
    if (this.pefLayer){
      this.pefLayer.remove();
      this.pefLayer = null;
    }
  },
  activated() {
    this.loadFraccion();
  },
  deactivated() {
    this.unloadFraccion();
  },
  data: () => ({
    fraccionLayer: null,
    pefLayer: null,
    fraccionPOIButton: null,
    type: "Entrada Fraccion"
  }),
  methods: {
    loadFraccion: function () {
      if (!this.wrkFraccion.geoInfo) {
        if (
          this.wrkFraccion[this.ModelTypes.Viviendas.field] &&
          this.wrkFraccion[this.ModelTypes.Viviendas.field].length > 0 &&
          this.wrkFraccion[this.ModelTypes.Viviendas.field][0].geoInfo
        ) {
          let coords =
            this.wrkFraccion[this.ModelTypes.Viviendas.field][0].geoInfo;
          this.map.fitBounds([
            [coords[0] - 0.01, coords[1] - 0.01],
            [coords[0] + 0.01, coords[1] + 0.01],
          ]);
        } else {
          this.map.fitBounds([
            [-33.183, -55.909],
            [-33.175, -55.85],
          ]);
        }
      } else {
        this.fraccionLayer = L.polygon(this.wrkFraccion.geoInfo, {
          interactive: false,
          pmIgnore: true,
        }).addTo(this.map);

        this.map.fitBounds(this.fraccionLayer.getBounds());
        this.loadPef();
      }
    },
    unloadFraccion: function () {
      if (this.fraccionLayer) {
        this.fraccionLayer.remove();
        this.fraccionLayer = null;
      }

      if (this.pefLayer){
        this.pefLayer.remove();
        this.pefLayer = null;
      }
    },
    click: function () {
      this.$store.dispatch("setWrkEdit", { type: [this.type] });
      this.$store.dispatch("setWrkSelected", {
        id: this.wrkFraccion,
        type: this.type,
        geo: false, // para que no abra el sidebar
      });
      this.map.pm.enableDraw("Marker", { preventMarkerRemoval: true });
      this.fraccionPOIButton.disable();
    },
    edit: function (e) {
      if (this.wrkState.edit && this.wrkState.edit.type[0] !== this.type) return;

      this.map.pm.disableDraw();
      let geom = e.layer;

      this.$toasted.show("¿Confirma el punto de acceso?", {
        action: [{
          text: "Aceptar",
          onClick: (e, toastObject) => {
            toastObject.goAway(0);
            this.setPAF(geom);
            this.fraccionPOIButton.enable();
          }
        },
        {
          text: "Cancelar",
          onClick: (e, toastObject) => {
            toastObject.goAway(0);
            geom.remove();
            this.fraccionPOIButton.enable();
          }
        }]
      });
    },
    loadPef: function (layer) {
      if (layer) {
        if (this.pefLayer) this.pefLayer.remove();
        this.pefLayer = layer;
      } else {
        if (!this.wrkFraccion.fraccionesWrapper.puntoAccesoGeoInfo){
          this.$toasted.show("La fraccion no tiene Punto de Acceso...", {
            type: "error",
            duration: 2000,
          });
          
          return;
        }

        try{
          this.pefLayer = L.marker(this.wrkFraccion.fraccionesWrapper.puntoAccesoGeoInfo).addTo(this.map);
        } catch(e){
          if (this.pefLayer){
            this.pefLayer.remove();
            this.pefLayer = null;
          }
          let msg = "Error al cargar Punto de Acceso: " + this.wrkFraccion.fraccionesWrapper.puntoAccesoGeoInfo;
          console.log(msg);
          this.$toasted.show(msg, { type: "error", duration: 4000 });          
        }
      }
    },
    setPAF: function (layer) {
      let point = [layer._latlng.lat, layer._latlng.lng];

      this.$store
        .dispatch("setPAF", point)
        .then(() => {
          this.loadPef(layer);

          return this.$toasted.show("Punto de entrada modificado", {
            type: "success",
            duration: 2000,
          });
        })
        .catch((e) => {
          layer.remove();

          return this.$toasted.show(
            "Error al modificar punto de entrada (" + e + ")",
            {
              type: "error",
              duration: 3000,
            }
          );
        })
        .finally(() => {
          this.map.pm.disableDraw();
          this.$store.dispatch("setWrkEdit", null);
          this.$store.dispatch("setWrkSelected", null);
        });
    },
  },
  computed: {
    ...mapState(["wrkFraccion", "wrkState"]),
  },
  watch: {
    wrkState(newSel) {
      if (!this.fraccionPOIButton) return;

      if (newSel.selected || newSel.edit) {
        this.fraccionPOIButton.disable();
      } else {
        this.fraccionPOIButton.enable();
      }
    },
  },
};
</script>
