<template>
  <v-row justify="center">
    <v-expansion-panels v-model="panel">
      <v-expansion-panel :key="0" @change="updateConst=!updateConst" :disabled="disableViviendas">
        <v-expansion-panel-header>
          <v-row justify="center">
            <v-col>
              <b>Viviendas</b> 
              <v-chip small class="ma-2">
                <v-avatar center>
                  {{ viviendasCount }}
                </v-avatar>
              </v-chip>
            </v-col>
            <v-col>
              <v-chip small class="ma-2"  color="red lighten-3" text-color="white">
                <v-avatar left>
                  {{ this.$store.getters.getModifiedViviendas() }}
                </v-avatar>
                <v-icon right>mdi-table-edit</v-icon>
              </v-chip>
            </v-col>
          </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <Viviendas :update="updateConst" :map="map"/>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel :key="1" @change="updateGalp=!updateGalp" :disabled="disableGalpones">
        <v-expansion-panel-header>
          <v-row justify="center">
            <v-col>
              <b>Galpones</b> 
              <v-chip small class="ma-2">
                <v-avatar center>
                  {{ galponesCount }}
                </v-avatar>
              </v-chip>
            </v-col>
            <v-col>
              <v-chip small class="ma-2"  color="red lighten-3" text-color="white">
                <v-avatar left>
                  {{ this.$store.getters.getModifiedGalpones() }}
                </v-avatar>
                <v-icon right>mdi-table-edit</v-icon>
              </v-chip>
            </v-col>
          </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <Galpones :update="updateGalp" :map="map"/>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel :key="2" @change="updateSalas=!updateSalas" :disabled="disableSalas">
        <v-expansion-panel-header>
          <v-row justify="center">
            <v-col>
              <b>Tambos</b> 
              <v-chip small class="ma-2">
                <v-avatar center>
                  {{ tambosCount }}
                </v-avatar>
              </v-chip>
            </v-col>
            <v-col>
              <v-chip small class="ma-2"  color="red lighten-3" text-color="white">
                <v-avatar left>
                  {{ this.$store.getters.getModifiedSalas() }}
                </v-avatar>
                <v-icon right>mdi-table-edit</v-icon>
              </v-chip>
            </v-col>
          </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <SalasOrdene :update="updateSalas" :map="map"/>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel :key="3" @change="updatePozos=!updatePozos" :disabled="disablePozos">
        <v-expansion-panel-header>
          <v-row justify="center">
            <v-col>
              <b>Pozos</b> 
              <v-chip small class="ma-2">
                <v-avatar center>
                  {{ pozosCount }}
                </v-avatar>
              </v-chip>
            </v-col>
            <v-col>
              <v-chip small class="ma-2"  color="red lighten-3" text-color="white">
                <v-avatar left>
                  {{ this.$store.getters.getModifiedPozos() }}
                </v-avatar>
                <v-icon right>mdi-table-edit</v-icon>
              </v-chip>
            </v-col>
          </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <Pozos :update="updatePozos" :map="map"/>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel :key="4" @change="updateAlam=!updateAlam" :disabled="disableAlambrados">
        <v-expansion-panel-header>
          <v-row justify="center">
            <v-col>
              <b>Alambrados</b> 
              <v-chip small class="ma-2">
                <v-avatar center>
                  {{ alambradosCount }}
                </v-avatar>
              </v-chip>
            </v-col>
            <v-col>
              <v-chip small class="ma-2"  color="red lighten-3" text-color="white">
                <v-avatar left>
                  {{ this.$store.getters.getModifiedAlambrados() }}
                </v-avatar>
                <v-icon right>mdi-table-edit</v-icon>
              </v-chip>
            </v-col>
          </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <Alambrados :update="updateAlam" :map="map"/>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel :key="5" @change="updateAgua=!updateAgua" :disabled="disableAgua">
        <v-expansion-panel-header>
          <v-row justify="center">
            <v-col>
              <b>Aguadas</b> 
              <v-chip small class="ma-2">
                <v-avatar center>
                  {{ aguadasCount }}
                </v-avatar>
              </v-chip>
            </v-col>
            <v-col>
              <v-chip small class="ma-2"  color="red lighten-3" text-color="white">
                <v-avatar left>
                  {{ this.$store.getters.getModifiedAgua() }}
                </v-avatar>
                <v-icon right>mdi-table-edit</v-icon>
              </v-chip>
            </v-col>
          </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <Aguadas :update="updateAgua" :map="map"/>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel :key="6" @change="updateOtrosElem=!updateOtrosElem" :disabled="disableOtrosElem">
        <v-expansion-panel-header>
          <v-row justify="center">
            <v-col>
              <b>Otros Elementos</b> 
              <v-chip small class="ma-2">
                <v-avatar center>
                  {{ otrosElementosCount }}
                </v-avatar>
              </v-chip>
            </v-col>
            <v-col>
              <v-chip small class="ma-2"  color="red lighten-3" text-color="white">
                <v-avatar left>
                  {{ this.$store.getters.getModifiedOtrosElem() }}
                </v-avatar>
                <v-icon right>mdi-table-edit</v-icon>
              </v-chip>
            </v-col>
          </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <OtrosElementos :update="updateOtrosElem" :map="map"/>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel :key="7" @change="updateGallineros=!updateGallineros" :disabled="disableGallineros">
        <v-expansion-panel-header>
          <v-row justify="center">
            <v-col>
              <b>Otras Construcciones</b> 
              <v-chip small class="ma-2">
                <v-avatar center>
                  {{ otrasConstruccionesCount }}
                </v-avatar>
              </v-chip>
            </v-col>
            <v-col>
              <v-chip small class="ma-2"  color="red lighten-3" text-color="white">
                <v-avatar left>
                  {{ this.$store.getters.getModifiedGallineros() }}
                </v-avatar>
                <v-icon right>mdi-table-edit</v-icon>
              </v-chip>
            </v-col>
          </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <GallinerosChiqueros :update="updateGallineros" :map="map"/>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel :key="8" @change="updateInstGanado=!updateInstGanado" :disabled="disableInstGanado">
        <v-expansion-panel-header>
          <v-row justify="center">
            <v-col>
              <b>Instalaciones Ganado</b> 
              <v-chip small class="ma-2">
                <v-avatar center>
                  {{ instalacionesGanadoCount }}
                </v-avatar>
              </v-chip>
            </v-col>
            <v-col>
              <v-chip small class="ma-2"  color="red lighten-3" text-color="white">
                <v-avatar left>
                  {{ this.$store.getters.getModifiedInstGanado() }}
                </v-avatar>
                <v-icon right>mdi-table-edit</v-icon>
              </v-chip>
            </v-col>
          </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <InstalacionesGanado :update="updateInstGanado" :map="map"/>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel :key="9" @change="updateMontes=!updateMontes" :disabled="disableMontes">
        <v-expansion-panel-header>
          <v-row justify="center">
            <v-col>
              <b>Montes</b> 
              <v-chip small class="ma-2">
                <v-avatar center>
                  {{ montesCount }}
                </v-avatar>
              </v-chip>
            </v-col>
            <v-col>
              <v-chip small class="ma-2"  color="red lighten-3" text-color="white">
                <v-avatar left>
                  {{ this.$store.getters.getModifiedMontes() }}
                </v-avatar>
                <v-icon right>mdi-table-edit</v-icon>
              </v-chip>
            </v-col>
          </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <Montes :update="updateMontes" :map="map"/>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel :key="10" @change="updateOtrasMejoras=!updateOtrasMejoras" :disabled="disableOtrasMejoras">
        <v-expansion-panel-header>
          <v-row justify="center">
            <v-col>
              <b>Otras Mejoras</b> 
              <v-chip small class="ma-2">
                <v-avatar center>
                  {{ otrasMejorasCount }}
                </v-avatar>
              </v-chip>
            </v-col>
            <v-col>
              <v-chip small class="ma-2"  color="red lighten-3" text-color="white">
                <v-avatar left>
                  {{ this.$store.getters.getModifiedOtrasMejoras() }}
                </v-avatar>
                <v-icon right>mdi-table-edit</v-icon>
              </v-chip>
            </v-col>
          </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <OtrasMejoras :update="updateOtrasMejoras" :map="map"/>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-row>
</template>

<script>
import Viviendas from "./Viviendas";
import Galpones from "./Galpones";
import SalasOrdene from "./SalasOrdene";
import Pozos from "./Pozos";
import Alambrados from "./Alambrados";
import OtrosElementos from "./OtrosElementos";
import GallinerosChiqueros from "./GallinerosChiqueros";
import InstalacionesGanado from "./InstalacionesGanado";
import Montes from "./Montes";
import OtrasMejoras from "./OtrasMejoras";
import Aguadas from "./Aguadas";
import { mapState } from "vuex";

export default {
  name: "Mejoras",
  components: { 
    Viviendas, Galpones, SalasOrdene, Pozos, Alambrados, OtrosElementos, GallinerosChiqueros, 
    InstalacionesGanado, Montes, OtrasMejoras, Aguadas },
  props: ["map", "sidebar"],
  data() {
    return {
      panel: [0,1,2,3,4,5,6,7,8,9,10],
      updateConst: false,
      updateAlam: false,
      updateOtrosElem: false,
      updateInstGanado: false,
      updateMontes: false,
      updateGalp: false,
      updateSalas: false,
      updatePozos: false,
      updateGallineros: false,
      updateOtrasMejoras: false,
      updateAgua: false
    };
  },
  computed: mapState({
    wrkFraccion: "wrkFraccion",
    wrkState: "wrkState",
    isInEdition(state){
      return state.wrkState.edit;
    },
    disableViviendas(state) {
      return state.wrkState.edit && state.wrkState.edit.type !== this.ModelTypes.Viviendas.name;
    },
    disableAlambrados(state) {
      return state.wrkState.edit && state.wrkState.edit !== this.ModelTypes.Alambrados.name;
    },
    disableOtrosElem(state) {
      return state.wrkState.edit && (
        state.wrkState.edit !== this.ModelTypes.BebederosComederos.name ||
        state.wrkState.edit !== this.ModelTypes.Bombas.name ||
        state.wrkState.edit !== this.ModelTypes.Caminos.name ||
        state.wrkState.edit !== this.ModelTypes.ConexionesUTE.name ||
        state.wrkState.edit !== this.ModelTypes.Molinos.name ||
        state.wrkState.edit !== this.ModelTypes.SistemasRiego.name ||
        state.wrkState.edit !== this.ModelTypes.TanquesAustralianos.name ||
        state.wrkState.edit !== this.ModelTypes.Tanques.name
      );
    },
    disableInstGanado(state) {
      return state.wrkState.edit && (
        state.wrkState.edit !== this.ModelTypes.Banios.name ||
        state.wrkState.edit !== this.ModelTypes.Bretes.name ||
        state.wrkState.edit !== this.ModelTypes.Cepos.name ||
        state.wrkState.edit !== this.ModelTypes.Corrales.name ||
        state.wrkState.edit !== this.ModelTypes.Embarcaderos.name ||
        state.wrkState.edit !== this.ModelTypes.Huevos.name ||
        state.wrkState.edit !== this.ModelTypes.Tubos.name ||
        state.wrkState.edit !== this.ModelTypes.Porteras.name
      );
    },
    disableMontes(state) {
      return state.wrkState.edit && (
        state.wrkState.edit !== this.ModelTypes.MontesForestales.name ||
        state.wrkState.edit !== this.ModelTypes.MontesFrutales.name ||
        state.wrkState.edit !== this.ModelTypes.MontesNativos.name
      );
    },
    disableGalpones(state) {
      return state.wrkState.edit && state.wrkState.edit !== this.ModelTypes.Galpones.name;
    },
    disableSalas(state) {
      return state.wrkState.edit && (
        state.wrkState.edit !== this.ModelTypes.SalasOrdene.name ||
        state.wrkState.edit !== this.ModelTypes.CorralesEspera.name ||
        state.wrkState.edit !== this.ModelTypes.SalasFrio.name ||
        state.wrkState.edit !== this.ModelTypes.Decantadores.name
      );
    },
    disablePozos(state) {
      return state.wrkState.edit && (
        state.wrkState.edit !== this.ModelTypes.Pozos.name ||
        state.wrkState.edit !== this.ModelType.PozosBalde.name
      );
    },
    disableGallineros(state) {
      return state.wrkState.edit && state.wrkState.edit !== this.ModelTypes.GallinerosChiqueros.name;
    },
    disableOtrasMejoras(state) {
      return state.wrkState.edit && state.wrkState.edit !== this.ModelTypes.OtrasMejoras.name;
    },
    disableAgua(state) {
      return state.wrkState.edit && (
        state.wrkState.edit !== this.ModelTypes.Aguadas.name ||
        state.wrkState.edit !== this.ModelTypes.Polders.name
      );
    },
    viviendasCount(state){
      return state.wrkFraccion && state.wrkFraccion.viviendas ? state.wrkFraccion.viviendas.length : 0;
    },
    galponesCount(state){
      return state.wrkFraccion && state.wrkFraccion.galpones ? state.wrkFraccion.galpones.length : 0;
    },
    tambosCount(state){
      if (!state.wrkFraccion) return 0;

      let total = 0;

      if (state.wrkFraccion.salasOrdene) total+=state.wrkFraccion.salasOrdene.length; 
      if (state.wrkFraccion.decantadores) total+=state.wrkFraccion.decantadores.length;
      if (state.wrkFraccion.salasFrio) total+=state.wrkFraccion.salasFrio.length;
      if (state.wrkFraccion.corralesEspera) total+=state.wrkFraccion.corralesEspera.length;

      return total;
    },
    pozosCount(state){
      if (!state.wrkFraccion) return 0;

      let total = 0;

      if (state.wrkFraccion.pozos) total+=state.wrkFraccion.pozos.length; 
      if (state.wrkFraccion.pozosBalde) total+=state.wrkFraccion.pozosBalde.length;

      return total;
    },
    alambradosCount(state){
      if (!state.wrkFraccion) return 0;

      let total = 0;

      if (state.wrkFraccion.alambrados) total+=state.wrkFraccion.alambrados.length; 
      if (state.wrkFraccion.guardaPatios) total+=state.wrkFraccion.guardaPatios.length;

      return total;
    },
    aguadasCount(state){
      if (!state.wrkFraccion) return 0;

      let total = 0;

      if (state.wrkFraccion.aguadas) total+=state.wrkFraccion.aguadas.length; 
      if (state.wrkFraccion.polders) total+=state.wrkFraccion.polders.length;

      return total;
    },
    otrosElementosCount(state){
      if (!state.wrkFraccion) return 0;

      let total = 0;

      if (state.wrkFraccion.bebederos) total+=state.wrkFraccion.bebederos.length; 
      if (state.wrkFraccion.bombas) total+=state.wrkFraccion.guardaPatios.length;
      if (state.wrkFraccion.caminos) total+=state.wrkFraccion.caminos.length;
      if (state.wrkFraccion.ute) total+=state.wrkFraccion.ute.length;
      if (state.wrkFraccion.molinos) total+=state.wrkFraccion.molinos.length;
      if (state.wrkFraccion.sistemasRiego) total+=state.wrkFraccion.sistemasRiego.length;
      if (state.wrkFraccion.tanquesAustralianos) total+=state.wrkFraccion.tanquesAustralianos.length;
      if (state.wrkFraccion.tanques) total+=state.wrkFraccion.tanques.length;

      return total;
    },
    otrasConstruccionesCount(state){
      return state.wrkFraccion && state.wrkFraccion.gallineros ? state.wrkFraccion.gallineros.length : 0;      
    },
    instalacionesGanadoCount(state){
      if (!state.wrkFraccion) return 0;

      let total = 0;
      
      if (state.wrkFraccion.banios) total+=state.wrkFraccion.banios.length; 
      if (state.wrkFraccion.bretes) total+=state.wrkFraccion.bretes.length;
      if (state.wrkFraccion.corrales) total+=state.wrkFraccion.corrales.length;
      if (state.wrkFraccion.cepos) total+=state.wrkFraccion.cepos.length;
      if (state.wrkFraccion.embarcaderos) total+=state.wrkFraccion.embarcaderos.length;
      if (state.wrkFraccion.huevos) total+=state.wrkFraccion.huevos.length;
      if (state.wrkFraccion.tubos) total+=state.wrkFraccion.tubos.length;
      if (state.wrkFraccion.porteras) total+=state.wrkFraccion.porteras.length;

      return total;
    },
    montesCount(state){
      if (!state.wrkFraccion) return 0;

      let total = 0;

      if (state.wrkFraccion.montesForestales) total+=state.wrkFraccion.montesForestales.length; 
      if (state.wrkFraccion.montesFrutales) total+=state.wrkFraccion.montesFrutales.length;
      if (state.wrkFraccion.montesNativos) total+=state.wrkFraccion.montesNativos.length;

      return total;
    },
    otrasMejorasCount(state){
      return state.wrkFraccion && state.wrkFraccion.otrasMejoras ? state.wrkFraccion.otrasMejoras.length : 0;      
    }
  }),
  watch: {
    wrkState(newSel) {
      if (newSel.selected && newSel.selected.geo){
        this.sidebar.open('home');
        switch (newSel.selected.type){
          // Viviendas
          case this.ModelTypes.Viviendas.name:
            this.panel = 0;
            break;

          // Galpones
          case this.ModelTypes.Galpones.name:
            this.panel = 1;
            break;

          // Tambos
          case this.ModelTypes.SalasOrdene.name:
            this.panel = 2;
            break;
          case this.ModelTypes.CorralesEspera.name:
            this.panel = 2;
            break;
          case this.ModelTypes.SalasFrio.name:
            this.panel = 2;
            break;
          case this.ModelTypes.Decantadores.name:
            this.panel = 2;
            break;

          // Pozos
          case this.ModelTypes.Pozos.name:
            this.panel = 3;
            break;

          // Alambrados
          case this.ModelTypes.Alambrados.name:
            this.panel = 4;
            break;

          // Aguadas
          case  this.ModelTypes.Aguadas.name:
            this.panel = 5;
            break;

          // Otros Elementos
          case this.ModelTypes.BebederosComederos.name:
            this.panel = 6;
            break;
          case this.ModelTypes.Bombas.name:
            this.panel = 6;
            break;
          case this.ModelTypes.Caminos.name:
            this.panel = 6;
            break;
          case this.ModelTypes.ConexionesUTE.name:
            this.panel = 6;
            break;
          case this.ModelTypes.Molinos.name:
            this.panel = 6;
            break;
          case this.ModelTypes.SistemasRiego.name:
            this.panel = 6;
            break;
          case this.ModelTypes.TanquesAustralianos.name:
            this.panel = 6;
            break;
          case this.ModelTypes.Tanques.name:
            this.panel = 6;
            break;

          // Otras Construcciones
          case this.ModelTypes.GallinerosChiqueros.name:
            this.panel = 7;
            break;

          // Instalaciones Ganado
          case this.ModelTypes.Banios.name:
            this.panel = 8;
            break;
          case this.ModelTypes.Bretes.name:
            this.panel = 8;
            break;
          case this.ModelTypes.Corrales.name:
            this.panel = 8;
            break;
          case this.ModelTypes.Cepos.name:
            this.panel = 8;
            break;
          case this.ModelTypes.Embarcaderos.name:
            this.panel = 8;
            break;
          case this.ModelTypes.Huevos.name:
            this.panel = 8;
            break;
          case this.ModelTypes.Tubos.name:
            this.panel = 8;
            break;
          case this.ModelTypes.Porteras.name:
            this.panel = 8;
            break;

          // Montes
          case this.ModelTypes.MontesNativos.name:
            this.panel = 9;
            break;
          case this.ModelTypes.MontesForestales.name:
            this.panel = 9;
            break;
          case this.ModelTypes.MontesFrutales.name:
            this.panel = 9;
            break;

          // Otras Mejoras
          case this.ModelTypes.OtrasMejoras.name:
            this.panel = 10;
            break;
          default:
            break;    
        }
      } else if (!newSel.selected && !newSel.edit){
        this.panel = [];
      }       
    }
  }
};
</script>
