<template>
  <v-card color="grey lighten-4" flat height="50vh" tile>
    <v-toolbar flat dense>
      <v-switch v-model="isEdition" hint="Editar?" persistent-hint @change="edit"></v-switch>
      <v-btn :disabled="selLength === 0 || (isEdition && type !== null)" icon>
        <v-icon color="blue" @click.stop="dialog = true">mdi-table-edit</v-icon>
      </v-btn>

      <v-menu
        style="z-index: 9000"
        left
        bottom
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn :disabled="!isEdition || (isEdition && type !== null)" icon v-on="on" v-bind="attrs">
            <v-icon color="blue">mdi-plus</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item
            v-for="(item) in this.types"
            :key="item.name"
            @click="create(item.name)"
          >
            <v-list-item-title>{{ item.text }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn :disabled="!isEdition || (isEdition && (type !== null || selLength === 0))" icon>
        <v-icon color="blue" @click.stop="remove(types[0].name)">mdi-minus</v-icon>
      </v-btn>
      <v-btn :disabled="!isEdition || (isEdition && (type !== null || selLength === 0))" icon>
        <v-icon color="blue" @click.stop="move()">mdi-move-resize-variant</v-icon>
      </v-btn>
    </v-toolbar>
    <v-data-table
      :headers="headers"
      :items="itemsCollection"
      v-model="selected"
      @item-selected="itemSelected($event)"
      item-key="id"
      :single-select="true"
      show-select
      dense
      height="40vh"
      fixed-header
      disable-pagination
      hide-default-footer
      class="elevation-1"
    >
      <template v-slot:item="{item, isSelected, select}">
        <tr v-bind:class="{'red lighten-3': item.modified && !item.deleted}">
          <td>
            <v-checkbox v-show="!item.deleted" :value="isSelected" @change="select($event)"></v-checkbox>
            <v-icon v-show="item.deleted && item.deleted > 0">mdi-delete</v-icon>
          </td>
          <td>{{getTypeText(item.type)}}</td>
          <td>{{(item.tiposDeEstadosConservacionMejorasId) ? item.tiposDeEstadosConservacionMejorasId.nombre : null}}</td>
        </tr>
      </template>
    </v-data-table>
    <Banio
      :data="data"
      :dialog="dialog && ((selLength > 0 && selected[0].type === types[0].name) || (isEdition && type === types[0].name))"
      :edit="isEdition" :isCancelling="isCancelling"
      @commit="commit"
      @cancel="cancel"
      @close="close"
    />
    <Brete
      :data="data"
      :dialog="dialog && ((selLength > 0 && selected[0].type === types[1].name) || (isEdition && type === types[1].name))"
      :edit="isEdition" :isCancelling="isCancelling"
      @commit="commit"
      @cancel="cancel"
      @close="close"
    />
    <Corral
      :data="data"
      :dialog="dialog && ((selLength > 0 && selected[0].type === types[2].name) || (isEdition && type === types[2].name))"
      :edit="isEdition" :isCancelling="isCancelling"
      @commit="commit"
      @cancel="cancel"
      @close="close"
    />
    <Embarcadero
      :data="data"
      :dialog="dialog && ((selLength > 0 && selected[0].type === types[3].name) || (isEdition && type === types[3].name))"
      :edit="isEdition" :isCancelling="isCancelling"
      @commit="commit"
      @cancel="cancel"
      @close="close"
    />
    <Huevo
      :data="data"
      :dialog="dialog && ((selLength > 0 && selected[0].type === types[4].name) || (isEdition && type === types[4].name))"
      :edit="isEdition" :isCancelling="isCancelling"
      @commit="commit"
      @cancel="cancel"
      @close="close"
    />
    <Tubo
      :data="data"
      :dialog="dialog && ((selLength > 0 && selected[0].type === types[5].name) || (isEdition && type === types[5].name))"
      :edit="isEdition" :isCancelling="isCancelling"
      @commit="commit"
      @cancel="cancel"
      @close="close"
    />
    <Portera
      :data="data"
      :dialog="dialog && ((selLength > 0 && selected[0].type === types[6].name) || (isEdition && type === types[6].name))"
      :edit="isEdition" :isCancelling="isCancelling"
      @commit="commit"
      @cancel="cancel"
      @close="close"
    />
    <Cepo
      :data="data"
      :dialog="dialog && ((selLength > 0 && selected[0].type === types[7].name) || (isEdition && type === types[7].name))"
      :edit="isEdition" :isCancelling="isCancelling"
      @commit="commit"
      @cancel="cancel"
      @close="close"
    />
    <MoverMejora :show="showFracciones" @close="closeShowFracciones"/>
  </v-card>
</template>

<script>
import "@mdi/font/css/materialdesignicons.css";
import Banio from "./Banio";
import Brete from "./Brete";
import Corral from "./Corral";
import Cepo from "./Cepo";
import Embarcadero from "./Embarcadero";
import Huevo from "./Huevo";
import Tubo from "./Tubo";
import Portera from "./Portera";
import mejoras from "../mixins/mejoras";

export default {
  name: "InstalacionesGanado",
  components: { Banio, Brete, Corral, Cepo, Embarcadero, Huevo, Tubo, Portera },
  mixins: [mejoras],
  data: function() {
    return {
      types: [
        { name: this.ModelTypes.Banios.name, text: "Baños", colName: this.ModelTypes.Banios.field, geom: "Point"},
        { name: this.ModelTypes.Bretes.name, text: "Bretes", colName: this.ModelTypes.Bretes.field, geom: "Point"},
        { name: this.ModelTypes.Corrales.name, text: "Corrales", colName: this.ModelTypes.Corrales.field, geom: "Point"},
        { name: this.ModelTypes.Embarcaderos.name, text: "Embarcaderos", colName: this.ModelTypes.Embarcaderos.field, geom: "Point"},
        { name: this.ModelTypes.Huevos.name, text: "Huevos", colName: this.ModelTypes.Huevos.field, geom: "Point"},
        { name: this.ModelTypes.Tubos.name, text:this.ModelTypes.Tubos.name, colName: this.ModelTypes.Tubos.field, geom: "Point"},
        { name: this.ModelTypes.Porteras.name, text: "Porteras", colName: this.ModelTypes.Porteras.field, geom: "Point"},
        { name: this.ModelTypes.Cepos.name, text: "Cepos", colName: this.ModelTypes.Cepos.field, geom: "Point"}
      ],
      headers: [
        { text: "Tipo", value: "tiposDeMejorasId.nombre", sortable: false },
        { text: "Estado", value: "tiposDeEstadosConservacionMejorasId.nombre", sortable: false }
      ],
    };
  }
};
</script>
