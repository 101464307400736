import vuetify from './plugins/vuetify';
import Store from './store';
import router from './router';
import Toasted from 'vue-toasted';
import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import Constants from "@/plugins/Constants";
import soap from "@/plugins/soap";
import './registerServiceWorker';
import App from './App.vue'

Vue.use(VueAxios, axios);
Vue.use(Constants);
Vue.use(Toasted);
Vue.use(soap);

Vue.config.productionTip = false

//import es from 'vuetify/es5/locale/es'

new Vue({
  vuetify,
  store: Store,
  router,
  render: h => h(App),
  created(){
    if (navigator.storage && navigator.storage.estimate) {
      navigator.storage.estimate().then((quota) => {
        // quota.usage -> bytes en uso
        // quota.quota -> bytes disponibles
        const used = Math.trunc((quota.usage / quota.quota) * 100);
        const available = Math.trunc(quota.quota / (1024*1024));
        const msg = 'Espacio disponible: ' + available + ' MB, ' + used + '% usado';

        console.log(msg);
        this.$toasted.show(msg, {
          type: "info",
          duration: 5000,
        });        
      });
    }

    window.addEventListener('online', function(){
//      Vue.prototype.$ping().then((res) => {
        Store.dispatch('setConnectionStatus', true);
//      });
    });
    window.addEventListener('offline', function(){
//      Vue.prototype.$ping().then((res) => {
        Store.dispatch('setConnectionStatus', false);
//      });
    });
  }
}).$mount('#app')
