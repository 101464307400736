<template>
  <v-row justify="center">
    <v-dialog
      ref="salaDialog"
      v-if="data"
      class="over"
      :fullscreen="true"
      persistent
      v-model="show"
      width="500"
    >
      <!-- v-if="data" -->
      <v-card>
        <v-card-title>
          <span class="headline">Aguada</span>
        </v-card-title>
        <v-card-text>
          <v-form v-model="valid" ref="form">
            <v-container>
              <v-row>
                <v-textarea
                  :disabled="!edit"
                  v-model="data.descripcion"
                  label="Descripcion"
                ></v-textarea>
              </v-row>
              <v-row>
                <v-col cols="2">
                  <v-select 
                    :disabled="!edit"
                    label="*En construccion?"
                    v-model="data.enConstruccion"
                    :items="boolItems"
                    :menu-props="{closeOnContentClick: true}" 
                    :rules="[requiredBool('en construccion')]"
                  >
                    <template v-slot:prepend-item>
                      <v-list-item ripple @click="data.enConstruccion = null">
                        <v-list-item-action>
                          <v-icon>mdi-close-circle-outline</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title> Sin dato </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider class="mt-2"></v-divider>
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="2">
                  <v-select 
                    :disabled="!edit"
                    label="Propiedad INC?"
                    v-model="data.propiedadINC"
                    :menu-props="{closeOnContentClick: true}"
                    :items="boolItems" 
                  >
                    <template v-slot:prepend-item>
                      <v-list-item ripple @click="data.propiedadINC = null">
                        <v-list-item-action>
                          <v-icon>mdi-close-circle-outline</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title> Sin dato </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider class="mt-2"></v-divider>
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="2">
                  <v-text-field
                    :disabled="!edit"
                    v-model="data.porcentajePropiedadDelColono"
                    label="Derecho indemnizatorio(%)"
                    type="number"
                    onkeydown="return (
                                event.keyCode !== 69 &&
                                event.keyCode !== 188 &&
                                event.keyCode !== 189 &&
                                event.keyCode !== 190
                    )"
                    :rules="[num_range('Derecho indem(%)', 0, 100)]"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3">
                <v-select return-object
                  :disabled="!edit"
                  label="*Estado conservacion"
                  required
                  v-model="data.tiposDeEstadosConservacionMejorasId"
                  :items="$store.state.tiposDeEstadosConservacionMejoras"
                  item-text="nombre"
                  :menu-props="{closeOnContentClick: true}"
                  :rules="[required('estado de conservacion')]"
                >
                    <template v-slot:prepend-item>
                      <v-list-item ripple @click="data.tiposDeEstadosConservacionMejorasId = null">
                        <v-list-item-action>
                          <v-icon>mdi-close-circle-outline</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title> Sin dato </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider class="mt-2"></v-divider>
                    </template>
                </v-select>
                </v-col>
                <v-col cols="3">
                <v-select return-object
                  :disabled="!edit"
                  label="*Tipo"
                  v-model="data.tiposDeReservaDeAguaId"
                  item-text="nombre"
                  :items="tiposDeReservaDeAgua"
                  :menu-props="{closeOnContentClick: true}"
                  :rules="[required('tipo')]"
                >
                    <template v-slot:prepend-item>
                      <v-list-item ripple @click="data.tiposDeReservaDeAguaId = null">
                        <v-list-item-action>
                          <v-icon>mdi-close-circle-outline</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title> Sin dato </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider class="mt-2"></v-divider>
                    </template>
                </v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="2">
                <v-text-field
                  :disabled="!edit"
                  label="*Largo"
                  :hint="largoHint"
                  v-model="data.largo"
                  type="number"
                    onkeydown="return (
                                event.keyCode !== 69 &&
                                event.keyCode !== 188 &&
                                event.keyCode !== 189 &&
                                event.keyCode !== 190
                    )"
                  :rules="[required('largo'), positive('largo')]"
                ></v-text-field>
                </v-col>
                <v-col cols="2">
                <v-text-field
                  :disabled="!edit"
                  label="*Alto"
                  :hint="altoHint"
                  v-model="data.altura"
                  type="number"
                    onkeydown="return (
                                event.keyCode !== 69 &&
                                event.keyCode !== 188 &&
                                event.keyCode !== 189 &&
                                event.keyCode !== 190
                    )"
                  :rules="[required('alto'), positive('alto')]"
                ></v-text-field>
                </v-col>
              </v-row>
              <!--v-row v-if="data.tipo && data.tipo.id === 4">
                <v-col cols="2">
                <v-text-field
                  :disabled="!edit"
                  label="*Ancho"
                  v-model="data.ancho"
                  :hint="anchoHint"
                  type="number"
                    onkeydown="return (
                                event.keyCode !== 69 &&
                                event.keyCode !== 188 &&
                                event.keyCode !== 189 &&
                                event.keyCode !== 190
                    )"
                  :rules="[required('ancho')]"
                ></v-text-field>
                </v-col>
                <v-col cols="2">
                <v-text-field
                  :disabled="!edit"
                  label="*Profundidad"
                  v-model="data.profundidad"
                  :hint="profundidadHint"
                  type="number"
                    onkeydown="return (
                                event.keyCode !== 69 &&
                                event.keyCode !== 188 &&
                                event.keyCode !== 189 &&
                                event.keyCode !== 190
                    )"
                  :rules="[required('profundidad')]"
                ></v-text-field>
                </v-col>
              </v-row -->
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn v-if="edit" color="primary" :disabled="!valid || this.isCancelling" @click="commit">Guardar</v-btn>
          <v-btn v-if="edit" @click="cancel" :disabled="this.isCancelling">Cancelar</v-btn>
          <v-btn v-if="!edit" @click="close" :disabled="this.isCancelling">Cerrar</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import "@mdi/font/css/materialdesignicons.css";
import validations from "../mixins/validations.js";

export default {
  name: "Aguada",
  props: ["data", "dialog", "edit", "isCancelling"],
  mixins: [validations],
  mounted() {},
  data() {
    return {
      valid: false,
      largoHint: '',
      anchoHint: '',
      altoHint: '',
      profundidadHint: '',
      boolItems: [{text: 'Si', value: true}, {text: 'No', value: false}],
      required(propertyType) {
        return (v) => !!v || `Debe ingresar ${propertyType}`;
      },
      positive(propertyType) {
        return (v) => !/^-./.test(v) || `${propertyType} no acepta numeros negativos`;
      },
      length(propertyType, len) {
        return (v) =>
          (v && v.length == len) || `${propertyType} debe tener largo ${len}`;
      },
    };
  },
  methods: {
    close: function () {
      this.$emit("close");
    },
    commit: function () {
      this.$emit("commit");
    },
    cancel: function () {
      this.$emit("cancel");
    },
  },
  computed: {
    tiposDeReservaDeAgua(){
      return this.$store.state.tiposDeReservaDeAgua.filter(el => el.codigo === 'REP' || el.codigo === 'TAJ');
    },
    largo() {
      return (this.data) ? this.data.largo : null;
    },
    alto() {
      return (this.data) ? this.data.alto : null;
    },
    ancho() {
      return (this.data) ? this.data.ancho : null;
    },
    profundidad() {
      return (this.data) ? this.data.profundidad : null;
    },
    show: {
      get() {
        return this.dialog;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
  },
  watch: {
    largo(newSel){
      if (newSel > 999){
        this.largoHint = "atencion: largo mayor a 999...";
      } else {
        this.largoHint = "";
      }
    },
    ancho(newSel){
      if (newSel > 999){
        this.anchoHint = "atencion: ancho mayor a 999...";
      } else {
        this.anchoHint = "";
      }
    },
    alto(newSel){
      if (newSel > 99){
        this.altoHint = "atencion: alto mayor a 99...";
      } else {
        this.altoHint = "";
      }
    },
    profundidad(newSel){
      if (newSel > 99){
        this.profundidadHint = "atencion: profundidad mayor a 99...";
      } else {
        this.profundidadHint = "";
      }
    },
    show(newSel) {
      if (newSel) {
        this.$nextTick(function () {
          this.$refs.salaDialog.stackMinZIndex = 9000;
          this.$refs.form.validate();
        });
      }
    },
  },
};
</script>

<style scoped>
.over {
  z-index: 9000 !important;
}
</style>
