<template>
  <div></div>
</template>

<script>
import * as L from "leaflet";
import "leaflet-measure-path";
import geoMejoras from '../mixins/geoMejoras';

export default {
  name: "GeoAlambrados",
  mixins: [geoMejoras],
  data: function() {
    return {
      typeName: this.ModelTypes.Alambrados.name,
      colName: this.ModelTypes.Alambrados.field,
      normalIcon: L.icon({
        iconUrl: require('@/assets/SCAlambrados.svg'),
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        tooltipAnchor: [16, -28],
        shadowSize: [41, 41]
      }),
      selectedIcon: L.icon({
        iconUrl: require('@/assets/Seleccion_alambrado.svg'),
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        tooltipAnchor: [16, -28],
        shadowSize: [41, 41]
      }),
      styleSizeFactor: 2,
          normalStyle: {
            color: 'brown',
            weight: 8
          },
          selectedStyle: {
            color: 'yellow',
            weight: 8
          }
    }
  },
  methods: {
    createLayer: function(geom){
      return L.polyline(geom, { 
        showMeasurements: true, 
        measurementOptions: { 
          formatDistance: this.distanceInMeters
        }  
      });
    },
    getVertexs: function(geom){
      let res = [];

      geom._latlngs.forEach(element => {
        res.push([element.lat, element.lng]);
      });

      return res;
    }
  }
};
</script>

