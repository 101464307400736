<template>
  <v-card color="grey lighten-4" flat height="50vh" tile>
    <v-toolbar id="toolbar" flat dense>
      <v-switch v-model="isEdition" hint="Editar?" persistent-hint @change="edit"></v-switch>
      <v-btn :disabled="selLength === 0 || (isEdition && type !== null)" icon>
        <v-icon color="blue" @click.stop="dialog = true">mdi-table-edit</v-icon>
      </v-btn>

      <v-menu style="z-index: 9000" left bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn :disabled="!isEdition || (isEdition && type !== null)" icon v-on="on" v-bind="attrs">
            <v-icon color="blue">mdi-plus</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item
            v-for="(item) in this.types"
            :key="item.name"
            @click="create(item.name)"
          >
            <v-list-item-title>{{ item.text }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn :disabled="!isEdition || (isEdition && (type !== null || selLength === 0))" icon>
        <v-icon color="blue" @click.stop="remove(types[0].name)">mdi-minus</v-icon>
      </v-btn>
      <v-btn :disabled="!isEdition || (isEdition && (type !== null || selLength === 0))" icon>
        <v-icon color="blue" @click.stop="move()">mdi-move-resize-variant</v-icon>
      </v-btn>
    </v-toolbar>
    <v-data-table
      :headers="headers"
      :items="itemsCollection"
      v-model="selected"
      item-key="id"
      @item-selected="itemSelected($event)"
      :single-select="true"
      show-select
      dense
      height="40vh"
      fixed-header
      disable-pagination
      hide-default-footer
      class="elevation-1"
    >
      <template v-slot:item="{item, isSelected, select}">
        <tr v-bind:class="{'red lighten-3': item.modified && !item.deleted}">
          <td>
            <v-checkbox v-show="!item.deleted" :value="isSelected" @change="select($event)"></v-checkbox>
            <v-icon v-show="item.deleted && item.deleted > 0">mdi-delete</v-icon>
          </td>
          <td>{{(item.tiposDeMejorasId) ? item.tiposDeMejorasId.nombre : null}}</td>
          <td v-if="item.tiposDeEstadoConservacionConstruccionesId">{{item.tiposDeEstadoConservacionConstruccionesId.nombre}}</td>
          <td v-if="item.tiposDeEstadosConservacionMejorasId">{{item.tiposDeEstadosConservacionMejorasId.nombre}}</td>
          <td v-if="item.tiposDeEstadosConservacionCamaraId">Camara: {{item.tiposDeEstadosConservacionCamaraId.nombre}}</td>
          <td v-if="item.tiposDeEstadosConservacionFosaId">Fosa: {{item.tiposDeEstadosConservacionFosaId.nombre}}</td>
        </tr>
      </template>
    </v-data-table>
    <SalaOrdene
      :data="data"
      :dialog="dialog && ((selLength > 0 && selected[0].type === types[0].name) || (isEdition && type === types[0].name))"
      :edit="isEdition" :isCancelling="isCancelling"
      @commit="commit"
      @cancel="cancel"
      @close="close"
    />
    <CorralEspera
      :data="data"
      :dialog="dialog && ((selLength > 0 && selected[0].type === types[1].name) || (isEdition && type === types[1].name))"
      :edit="isEdition" :isCancelling="isCancelling"
      @commit="commit"
      @cancel="cancel"
      @close="close"
    />
    <SalaFrio
      :data="data"
      :dialog="dialog && ((selLength > 0 && selected[0].type === types[2].name) || (isEdition && type === types[2].name))"
      :edit="isEdition" :isCancelling="isCancelling"
      @commit="commit"
      @cancel="cancel"
      @close="close"
    />
    <Decantador
      :data="data"
      :dialog="dialog && ((selLength > 0 && selected[0].type === types[3].name) || (isEdition && type === types[3].name))"
      :edit="isEdition" :isCancelling="isCancelling"
      @commit="commit"
      @cancel="cancel"
      @close="close"
    />
    <MoverMejora :show="showFracciones" @close="closeShowFracciones"/>
  </v-card>
</template>

<script>
import "@mdi/font/css/materialdesignicons.css";
import SalaOrdene from "./SalaOrdene";
import Decantador from "./Decantador";
import CorralEspera from "./CorralEspera";
import SalaFrio from "./SalaFrio";
import mejoras from "../mixins/mejoras";

export default {
  name: "SalasOrdene",
  components: { SalaOrdene, Decantador, CorralEspera, SalaFrio },
  mixins: [mejoras],
  data: function() {
    return {
      types: [
        { name: this.ModelTypes.SalasOrdene.name, text: "Salas de Ordeñe", colName: this.ModelTypes.SalasOrdene.field, geom: "Point"},
        { name: this.ModelTypes.CorralesEspera.name, text: "Corral de Espera", colName: this.ModelTypes.CorralesEspera.field, geom: "Point"},
        { name: this.ModelTypes.SalasFrio.name, text: "Sala de Frio /  Queseria", colName: this.ModelTypes.SalasFrio.field, geom: "Point"},
        { name: this.ModelTypes.Decantadores.name, text: "Decantador", colName: this.ModelTypes.Decantadores.field, geom: "Point"}
      ],
      headers: [
        { text: "Tipo", value: "tiposDeMejorasId.nombre", sortable: false },
        { text: "Estado", value: "", sortable: false }
      ]
    };
  }
};
</script>
