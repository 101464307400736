<template>
  <div id="sidebar" class="leaflet-sidebar collapsed">
    <div class="leaflet-sidebar-tabs">
      <ul role="tablist">
        <li>
          <a href="#home" role="tab">
            <v-icon>mdi-database</v-icon>
          </a>
        </li>
      </ul>
      <ul role="tablist">
        <li>
          <a href="#download">
            <v-icon>mdi-tools</v-icon>
          </a>
        </li>
      </ul>
    </div>

    <div class="leaflet-sidebar-content">
      <div class="leaflet-sidebar-pane" id="home">
        <Mejoras :map="map" :sidebar="sidebar"/>
      </div>
      <div class="leaflet-sidebar-pane" id="download">
        <OfflineInfo :map="map" :osmLayer="osmLayer" :googleLayer="googleLayer" @printMap="printMap"/>
      </div>
    </div>
  </div>
</template>
<script>
import * as L from "leaflet";
import "leaflet-sidebar-v2";
import 'leaflet-easyprint';
import OfflineInfo from "./OfflineInfo";
import Mejoras from "./../Mejoras";
import "@mdi/font/css/materialdesignicons.css";

export default {
  name: "Sidebar",
  components: { OfflineInfo, Mejoras },
  props: ["map", "osmLayer", "googleLayer"],
  data: () => ({
    sidebar: null,
    printPlugin: null,
    fileName: 'map.png',
  }),
  mounted() {
    this.sidebar = L.control
      .sidebar({
        autopan: true,
        closeButton: true,
        container: "sidebar",
        position: "left"
      })
      .addTo(this.map);

    this.printPlugin = L.easyPrint({
      hidden: true,
      hideControlContainer: true,
      sizeModes: ["Current", "A4Portrait", "A4Landscape"],
      exportOnly: true
    }).addTo(this.map);
  },
  methods: {
    printMap: function(){
      this.sidebar.close();
      setTimeout(this.print, 1000);
    },
    print: function(){
      this.printPlugin.printMap('CurrentSize', this.fileName);      
    }
  }
};
</script>
<style scoped src='leaflet-sidebar-v2/css/leaflet-sidebar.min.css'></style>

<style scoped>

#sidebar {
  height: 90%;
}
</style>