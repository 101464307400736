import { render, staticRenderFns } from "./Tubo.vue?vue&type=template&id=68ee97b8&scoped=true"
import script from "./Tubo.vue?vue&type=script&lang=js"
export * from "./Tubo.vue?vue&type=script&lang=js"
import style0 from "./Tubo.vue?vue&type=style&index=0&id=68ee97b8&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68ee97b8",
  null
  
)

export default component.exports