<template>
  <v-container>
    <v-row>
    <v-toolbar dense flat color="white">
        <v-icon v-if="connected" color="success">mdi-lan-connect</v-icon>
        <v-icon v-if="!connected" color="error">mdi-lan-disconnect</v-icon>
        <v-spacer></v-spacer>
        <v-chip v-if="execute" class="ma-2" outlined small color="primary"><v-icon left>mdi-cogs</v-icon>{{ executeText }}</v-chip>
        <v-progress-circular v-if="execute" :width="2" :size="20" indeterminate color="primary"></v-progress-circular>
        <v-spacer></v-spacer>
        <v-menu>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="doBatch()">
              <v-list-item-title>Ejecutar procesos automaticos</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
  </v-toolbar>
  </v-row>
  </v-container>
</template>

<script>
import batch from "../mixins/batch.js";
import { mapState } from "vuex";

export default {
  name: 'ConnectionStatus',
  mixins: [batch],
  data: function(){
    return {
      executeText: '',
      execute: false
    }
  },
  methods: {
    datosOperativos: function(){
        console.log("Descargando datos operativos...");
        this.executeText = "Descargando datos operativos...";

        return this.$getDatosOperativos().then((res) => {
            return this.$store.dispatch('loadDatosOperativos', res);
          })
          .catch((error) => {
            console.log('Error al cargar codigos operativos! (' + error + ')');
          });
    },
    downloadPhotos(){
        console.log("Descargando fotos...");
        this.executeText = "Descargando fotos..."
        let batch = [];

        for (let element of this.fracciones){
          batch.push(this.downloadFotosFraccion(element));          
        }

        return Promise.all(batch);
    }, 
    uploadPhotos(){
        console.log("Subiendo fotos...");
        this.executeText = "Subiendo fotos...";
        let batch = [];

        for (let element of this.fracciones){
          batch.push(this.uploadFotosFraccion(element));          
        }

        return Promise.all(batch);
    }, 
    doBatch(check){
        if (check && !this.checkTimeDifference('getDatosOperativosTimestamp', 1)){
          console.log('No hace 1 hora que se cargaron los datos operativos...');
          return;
        }

        this.execute = true;
        this.executeText = "Realizando operaciones automaticas...";
        console.log("Realizando operaciones automaticas...");

        this.datosOperativos()
        .then(() => this.downloadPhotos())
        .then(() => this.uploadPhotos())
        .catch((error) => { console.log('Error! (' + error + ')')})
        .finally(() => {
          this.execute = false;
          this.executeText = "";
          console.log("Hecho");
        });
    },
    checkTimeDifference(getter, value, unit){
      let timestamp = this.$store.getters[getter];
      let now = Date.now();
      let divisor = (!unit || unit === 'h') ? (1000 * 3600) : (1000 * 3600 * 24);
      let diff = timestamp ? (now - timestamp) / divisor :  null;

      return (!diff || diff >= value);
    }
  },
  computed: {
    connected() {
      return this.$store.getters.getConnectionStatus;
    },
    ...mapState(["fracciones"])
  },
  /*
  watch: {
    connected(newVal){
      if (newVal){
        this.doBatch(true);
      }
    }
  }
  */
};
</script>
