<template>
  <v-row justify="center">
    <v-dialog
      ref="constDialog"
      v-if="data"
      class="over"
      :fullscreen="true"
      persistent
      v-model="show"
      width="500"
    >
      <!-- v-if="data" -->
      <v-card>
        <v-card-title>
          <span class="headline">Sala de Frio</span>
        </v-card-title>
        <v-card-text>
          <v-form v-model="valid" ref="form">
            <v-container>
              <v-row>
                <v-col>
                <v-textarea
                  :disabled="!edit"
                  v-model="data.descripcion"
                  label="Descripcion"
                ></v-textarea>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="2">
                  <v-select 
                    :disabled="!edit"
                    label="*En construccion?"
                    v-model="data.enConstruccion"
                    :items="boolItems" 
                    :menu-props="{closeOnContentClick: true}"
                    :rules="[requiredBool('en construccion')]"
                  >
                    <template v-slot:prepend-item>
                      <v-list-item ripple @click="data.enConstruccion = null">
                        <v-list-item-action>
                          <v-icon>mdi-close-circle-outline</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title> Sin dato </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider class="mt-2"></v-divider>
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="2">
                  <v-select 
                    :disabled="!edit"
                    label="Propiedad INC?"
                    v-model="data.propiedadINC"
                    :menu-props="{closeOnContentClick: true}"
                    :items="boolItems" 
                  >
                    <template v-slot:prepend-item>
                      <v-list-item ripple @click="data.propiedadINC = null">
                        <v-list-item-action>
                          <v-icon>mdi-close-circle-outline</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title> Sin dato </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider class="mt-2"></v-divider>
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="2">
                  <v-text-field
                    :disabled="!edit"
                    v-model="data.porcentajePropiedadDelColono"
                    label="Derecho indemnizatorio(%)"
                    type="number"
                    onkeydown="return event.keyCode !== 69 && event.keyCode !== 188 && event.keyCode !== 189 && event.keyCode !== 190"
                    :rules="[num_range('Derecho indem(%)', 0, 100)]"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="2">
                  <v-select return-object
                    :disabled="!edit"
                    label="*Material pared"
                    v-model="data.tiposDeMaterialesParedesId"
                    :items="$store.state.tiposDeMaterialesParedes"
                    item-text="nombre"
                    :menu-props="{closeOnContentClick: true}"
                    :rules="[required('material pared')]"
                  >
                    <template v-slot:prepend-item>
                      <v-list-item ripple @click="data.tiposDeMaterialesParedesId = null">
                        <v-list-item-action>
                          <v-icon>mdi-close-circle-outline</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title> Sin dato </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider class="mt-2"></v-divider>
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="2">
                  <v-select return-object
                    :disabled="!edit"
                    label="*Material techo"
                    v-model="data.tiposDeMaterialesTechosId"
                    :items="$store.state.tiposDeMaterialesTechos"
                  item-text="nombre"
                    :menu-props="{closeOnContentClick: true}"
                    :rules="[required('material techo')]"
                  >
                    <template v-slot:prepend-item>
                      <v-list-item ripple @click="data.tiposDeMaterialesTechosId = null">
                        <v-list-item-action>
                          <v-icon>mdi-close-circle-outline</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title> Sin dato </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider class="mt-2"></v-divider>
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="2">
                  <v-select return-object
                    :disabled="!edit"
                    label="*Material piso"
                    v-model="data.tiposDeMaterialesPisosId"
                    :items="$store.state.tiposDeMaterialesPisos"
                  item-text="nombre"
                    :menu-props="{closeOnContentClick: true}"
                    :rules="[required('material piso')]"
                  >
                    <template v-slot:prepend-item>
                      <v-list-item ripple @click="data.tiposDeMaterialesPisosId = null">
                        <v-list-item-action>
                          <v-icon>mdi-close-circle-outline</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title> Sin dato </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider class="mt-2"></v-divider>
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="2">
                  <v-select return-object
                    :disabled="!edit"
                    label="*Estado conservacion"
                    v-model="data.tiposDeEstadoConservacionConstruccionesId"
                    :items="$store.state.tiposDeEstadosConservacionConstrucciones"
                  item-text="nombre"
                    :menu-props="{closeOnContentClick: true}"
                    :rules="[required('estado conservacion')]"
                  >
                    <template v-slot:prepend-item>
                      <v-list-item ripple @click="data.tiposDeEstadoConservacionConstruccionesId = null">
                        <v-list-item-action>
                          <v-icon>mdi-close-circle-outline</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title> Sin dato </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider class="mt-2"></v-divider>
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="2">
                  <v-text-field
                    :disabled="!edit"
                    v-model="data.metrosCuadrados"
                    label="*M2"
                    type="number"
                    onkeydown="return event.keyCode !== 69 && event.keyCode !== 188 && event.keyCode !== 189 && event.keyCode !== 190"
                    :rules="[required('metros cuadrados'), num_range('metros cuadrados', 9, 999)]"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-subheader>Otros M2</v-subheader>
              <v-row>
                <v-col cols="2">
                  <v-select return-object
                    :disabled="!edit"
                    label="Tipo m2"
                    v-model="tipoM2"
                  item-text="nombre"
                    :items="$store.state.tiposDeM2Edificados"
                  >
                  </v-select>
                </v-col>
                <v-col cols="1">
                  <v-text-field
                    :disabled="!edit"
                    v-model="m2"
                    label="m2"
                    type="number"
                    onkeydown="return event.keyCode !== 69 && event.keyCode !== 188 && event.keyCode !== 189 && event.keyCode !== 190"
                    :rules="[positive('m2')]"
                  ></v-text-field>
                </v-col>
                <v-col cols="1">
                  <v-btn :disabled="!edit || (edit && (!tipoM2 || !m2))" icon>
                    <v-icon @click.stop="addM2">mdi-arrow-right-thick</v-icon>
                  </v-btn>
                  <v-btn
                    :disabled="!edit || (edit && m2Selected.length === 0)"
                    icon
                  >
                    <v-icon @click.stop="delM2">mdi-arrow-left-thick</v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="6">
                  <v-data-table
                    :disabled="!edit"
                    :headers="m2Headers"
                    :items="data.otrosM2EdificadosSIMCollection"
                    v-model="m2Selected"
                    item-key="id"
                    @item-selected="m2SelEvent($event)"
                    :single-select="true"
                    show-select
                    hide-default-footer
                    dense
                    class="elevation-1"
                  ></v-data-table>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-row>
                <v-col cols="2">
                  <v-select 
                    :disabled="!edit"
                    label="Mevir"
                    v-model="data.mevir"
                    :menu-props="{closeOnContentClick: true}"
                    :items="boolItems" 
                  >
                    <template v-slot:prepend-item>
                      <v-list-item ripple @click="data.mevir = null">
                        <v-list-item-action>
                          <v-icon>mdi-close-circle-outline</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title> Sin dato </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider class="mt-2"></v-divider>
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="2">
                  <v-text-field
                    :disabled="!edit"
                    v-model="data.anioConstruccion"
                    label="Año construccion"
                    type="number"
                    onkeydown="return event.keyCode !== 69 && event.keyCode !== 188 && event.keyCode !== 189 && event.keyCode !== 190"
                    :rules="[num_range('año', 1900, 2050)]"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn v-if="edit" color="primary" :disabled="!valid || this.isCancelling" @click="commit"
            >Guardar</v-btn
          >
          <v-btn v-if="edit" @click="cancel" :disabled="this.isCancelling">Cancelar</v-btn>
          <v-btn v-if="!edit" @click="close" :disabled="this.isCancelling">Cerrar</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import "@mdi/font/css/materialdesignicons.css";
import validations from "../mixins/validations.js";

export default {
  name: "SalaFrio",
  props: ["data", "dialog", "edit", "isCancelling"],
  mixins: [validations],
  mounted() {},
  data() {
    return {
      m2: null,
      m2Headers: [
        { text: "Metros", value: "valor" },
        { text: "Tipo", value: "tiposDeM2EdificadosId.nombre" },
      ],
      tipoM2: null,
      m2Selected: [],
      valid: false,
      boolItems: [{text: 'Si', value: true}, {text: 'No', value: false}]
    };
  },
  methods: {
    m2SelEvent: function () {},
    addM2: function () {
      if (!this.data.otrosM2EdificadosSIMCollection){
        this.data.otrosM2EdificadosSIMCollection = [];
      }
      this.data.otrosM2EdificadosSIMCollection.push({
        activo: true,
        editable: false,
        id: this.General.tmpId + new Date().getTime(),
        valor: this.m2,
        tiposDeM2EdificadosId: this.tipoM2,
      });
      this.m2 = null;
      this.tipoM2 =  null;
    },
    delM2: function () {
      let index = this.data.otrosM2EdificadosSIMCollection.findIndex(
        (el) => el.id === this.m2Selected[0].id
      );
      if (index >= 0) {
        this.data.otrosM2EdificadosSIMCollection.splice(index, 1);
      }
    },
    close: function () {
      this.$emit("close");
    },
    commit: function () {
      this.$emit("commit");
    },
    cancel: function () {
      this.$emit("cancel");
    },
  },
  computed: {
    show: {
      get() {
        return this.dialog;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
  },
  watch: {
    show(newSel) {
      if (newSel) {
        this.$nextTick(function () {
          this.$refs.constDialog.stackMinZIndex = 9000;
          this.$refs.form.validate();
        });
      }
    }
  },
};
</script>

<style scoped>
.over {
  z-index: 9000 !important;
}
</style>
