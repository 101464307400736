<template>
  <v-container>
    <v-row justify="center">
      <v-dialog class="over" v-model="show" hide-overlay width="1250">
        <v-card>
          <!--v-card-title justify="center">Agregar foto a la mejora seleccionada</v-card-title -->
          <v-card-text>
            <v-row justify="center" align="center">
              <v-col cols="6">
                <v-row justify="center" align="center">
                  <v-select v-model="camera" label="Seleccione camara" :items="devices" item-text="label" item-value="deviceId">
                  </v-select>
                </v-row>
                <v-row justify="center">
                  <vue-web-cam
                    ref="webcam"
                    :device-id="deviceId"
                    screenshotFormat="image/jpeg"
                    width="600"
                    height="600"
                    @error="onError"
                    @cameras="onCameras"
                    @camera-change="onCameraChange"
                  />
                </v-row>
                <v-row justify="center">
                  <v-btn @click="onStart"
                    >Encender<v-icon>mdi-camera</v-icon></v-btn
                  >
                  <v-btn @click="onCapture"
                    >Foto<v-icon>mdi-camera-plus</v-icon></v-btn
                  >
                  <v-btn @click="onStop"
                    >Apagar<v-icon>mdi-camera-off</v-icon></v-btn
                  >
                </v-row>
              </v-col>
              <v-col cols="6">
                <v-row justify="center">
                  <v-img
                    v-if="img"
                    :src="img"
                    width="600"
                    height="600"
                    contain
                  />
                </v-row>
                <p></p>
                <v-row justify="center">
                  <v-btn v-if="img" @click="downloadImage()"
                    >Agregar Foto<v-icon>mdi-download</v-icon></v-btn
                  >
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import { WebCam } from "vue-web-cam";

export default {
  name: "Camera",
  components: { "vue-web-cam": WebCam },
  props: ["dialog", "idFraccion", "field", "idMejora"],
  data() {
    return {
      img: null,
      camera: null,
      deviceId: null,
      devices: [],
    };
  },
  methods: {
    downloadImage() {
      this.$store.dispatch("logFotos", new Date().toLocaleString());
      if (!("geolocation" in navigator)) {
        console.log("No se puede usar la geolocalizacion!!!");
        this.$store.dispatch("logFotos", "No se puede usar la geolocalizacion!!!");
        this.$toasted.show("No se puede usar la geolocalizacion!!!", {
          type: "error",
          duration: 4000,
        });

        return;
      }

      new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject, {
          enableHighAccuracy: true,
          timeout: 5000,
        });
      })
        .then((pos) => {
          this.$store.dispatch("logFotos", "Geolocalizacion obtenida");
          this.$store.dispatch("setImage", {
            idFraccion: this.idFraccion,
            field: this.field,
            idMejora: this.idMejora,
            idFoto: this.General.tmpId + new Date().getTime(),
            image: this.img,
            lat: pos.coords.latitude,
            long: pos.coords.longitude,
            onlyEdited: true
          });
        }
        )
        .then(() => new Promise((resolve) => {
          this.$store.dispatch("logFotos", "Foto agregada a la fraccion");
          console.log("Foto agregada a la fraccion");
          this.$toasted.show("Foto agregada a la fraccion", {
            type: "success",
            duration: 2500,
          });
          resolve();
        })
        ).catch((error) => {
          console.log("Error al agregar foto (" + error + ")");
          this.$store.dispatch("logFotos", "Error al agregar foto (" + error + ")");
          return this.$toasted.show("Error al agregar foto (" + error + ")", {
            type: "error",
            duration: 3000,
          });
        });
    },
    onCapture() {
      this.img = this.$refs.webcam.capture();
    },
    onError(error) {
      console.log("Error", error);
    },
    onCameras(cameras) {
      this.devices = cameras;
    },
    onCameraChange(deviceId) {
      this.deviceId = deviceId;
      this.camera = deviceId;
    },
    onStart() {
      this.$refs.webcam.start();
    },
    onStop() {
      this.$refs.webcam.stop();
    },
  },
  computed: {
    show: {
      get() {
        return this.dialog;
      },
      set(value) {
        if (!value) {
          this.img = null;
          this.$refs.webcam.stop();
          this.$emit("close");
        } else {
          console.log(this.devices);
        }
      },
    },
    device: function () {
      return this.devices.find((n) => n.deviceId === this.deviceId);
    },
  },

  watch: {
    camera: function (id) {
      this.deviceId = id;
    },
    devices: function () {
      // eslint-disable-next-line
      const [first, ...tail] = this.devices;
      if (first) {
        this.camera = first.deviceId;
        this.deviceId = first.deviceId;
      }
    },
  },
};
</script>

<style scoped>
.over {
  z-index: 9000 !important;
}
</style>
