<template>
  <div></div>
</template>

<script>
import * as L from "leaflet";
import geoMejoras from '../mixins/geoMejoras';

export default {
  name: "GeoSalasFrio",
  mixins: [geoMejoras],
  data() {
    return {
      typeName: this.ModelTypes.SalasFrio.name,
      colName: this.ModelTypes.SalasFrio.field,
      styleSizeFactor: 1,
      radius: 10,
      normalIcon: L.icon({
        iconUrl: require('@/assets/SCTambo.svg'),
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        tooltipAnchor: [16, -28],
        shadowSize: [41, 41]
      }),
      selectedIcon: L.icon({
        iconUrl: require('@/assets/Seleccion_tambos.svg'),
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        tooltipAnchor: [16, -28],
        shadowSize: [41, 41]
      })
      /*
      normalStyle: {
        color: "red",
        weight: 2
      },
      selectedStyle: {
        color: "yellow",
        weight: 2
      }
      */
    };
  },
  methods: {
    createLayer: function(geom){
      return L.marker(geom);
    },
    getVertexs: function(geom){
      return [geom._latlng.lat, geom._latlng.lng];
    }  
  }
};
</script>
