<template>
  <div>
    <Sidebar
      id="sidebar"
      v-if="map"
      :map="map"
      :osmLayer="osmLayer"
      :googleLayer="googleLayer"
    />
    <div id="map"></div>
    <GeoFraccion v-if="map" :map="map" />
    <Carousel :dialog="carouselDialog" :items="fotos" @close="closeCarousel"/>
    <Camera :dialog="cameraDialog" :idFraccion="selFraccionId" :field="selMejoraType" :idMejora="selMejoraId" @close="closeCamera"/>
    <GeoAlambrados v-if="map" :map="map" />
    <GeoViviendas v-if="map" :map="map" />
    <GeoGalpones v-if="map" :map="map" />
    <GeoSalasOrdene v-if="map" :map="map" />
    <GeoGuardaPatios v-if="map" :map="map" />
    <GeoCaminos v-if="map" :map="map" />
    <GeoPozos v-if="map" :map="map" />
    <GeoPozosBalde v-if="map" :map="map" />
    <GeoMolinos v-if="map" :map="map" />
    <GeoBombas v-if="map" :map="map" />
    <GeoBebederosComederos v-if="map" :map="map" />
    <GeoConexionesUTE v-if="map" :map="map" />
    <GeoTanquesAustralianos v-if="map" :map="map" />
    <GeoTanques v-if="map" :map="map" />
    <GeoTubos v-if="map" :map="map" />
    <GeoGallinerosChiqueros v-if="map" :map="map" />
    <GeoCorrales v-if="map" :map="map" />
    <GeoBanios v-if="map" :map="map" />
    <GeoEmbarcaderos v-if="map" :map="map" />
    <GeoHuevos v-if="map" :map="map" />
    <GeoBretes v-if="map" :map="map" />
    <GeoMontesNativos v-if="map" :map="map" />
    <GeoMontesForestales v-if="map" :map="map" />
    <GeoMontesFrutales v-if="map" :map="map" />
    <GeoOtrasMejoras v-if="map" :map="map" />
    <GeoPorteras v-if="map" :map="map" />
    <GeoAguadas v-if="map" :map="map" />
    <GeoCorralesEspera v-if="map" :map="map" />
    <GeoSalasFrio v-if="map" :map="map" />
    <GeoDecantadores v-if="map" :map="map" />
    <GeoPolders v-if="map" :map="map" />
  </div>
</template>
<script>
import "@mdi/font/css/materialdesignicons.css";
import "font-awesome/css/font-awesome.css";
import * as L from "leaflet";
import "@geoman-io/leaflet-geoman-free";
import "leaflet.offline";
import "leaflet.locatecontrol";
import "leaflet-measure-path";
import "leaflet-measure/dist/leaflet-measure.es";
import "leaflet-easybutton";
import Sidebar from "./Sidebar";
import GeoFraccion from "./../GeoFraccion";
import GeoAlambrados from "./../GeoAlambrados";
import GeoViviendas from "./../GeoViviendas";
import GeoGalpones from "./../GeoGalpones";
import GeoSalasOrdene from "./../GeoSalasOrdene";
import GeoGuardaPatios from "./../GeoGuardaPatios";
import GeoCaminos from "./../GeoCaminos";
import GeoPozos from "./../GeoPozos";
import GeoPozosBalde from "./../GeoPozosBalde";
import GeoMolinos from "./../GeoMolinos";
import GeoBombas from "./../GeoBombas";
import GeoBebederosComederos from "./../GeoBebederosComederos";
import GeoConexionesUTE from "./../GeoConexionesUTE";
import GeoTanquesAustralianos from "./../GeoTanquesAustralianos";
import GeoTanques from "./../GeoTanques";
import GeoTubos from "./../GeoTubos";
import GeoGallinerosChiqueros from "./../GeoGallinerosChiqueros";
import GeoCorrales from "./../GeoCorrales";
import GeoBanios from "./../GeoBanios";
import GeoEmbarcaderos from "./../GeoEmbarcaderos";
import GeoHuevos from "./../GeoHuevos";
import GeoBretes from "./../GeoBretes";
import GeoMontesNativos from "./../GeoMontesNativos";
import GeoMontesForestales from "./../GeoMontesForestales";
import GeoMontesFrutales from "./../GeoMontesFrutales";
import GeoOtrasMejoras from "./../GeoOtrasMejoras";
import GeoPorteras from "./../GeoPorteras";
import GeoAguadas from "./../GeoAguadas";
import GeoCorralesEspera from "./../GeoCorralesEspera";
import GeoSalasFrio from "./../GeoSalasFrio";
import GeoDecantadores from "./../GeoDecantadores";
import GeoPolders from "./../GeoPolders";
import Carousel from "../carousel/Carousel";
import Camera from "../carousel/Camera";
import { mapState } from "vuex";

export default {
  name: "Map",
  components: {
    Sidebar,
    GeoFraccion,
    GeoAlambrados,
    GeoViviendas,
    GeoGalpones,
    GeoSalasOrdene,
    GeoGuardaPatios,
    GeoCaminos,
    GeoPozos,
    GeoPozosBalde,
    GeoMolinos,
    GeoBombas,
    GeoBebederosComederos,
    GeoConexionesUTE,
    GeoTanquesAustralianos,
    GeoTanques,
    GeoTubos,
    GeoGallinerosChiqueros,
    GeoCorrales,
    GeoBanios,
    GeoEmbarcaderos,
    GeoHuevos,
    GeoBretes,
    GeoMontesNativos,
    GeoMontesForestales,
    GeoMontesFrutales,
    GeoOtrasMejoras,
    GeoPorteras,
    GeoAguadas,
    GeoCorralesEspera,
    GeoSalasFrio,
    GeoDecantadores,
    GeoPolders,
    Carousel,
    Camera
  },
  data: () => ({
    map: null,
    zoom: 7,
    center: L.latLng(-33.183, -55.909),
    options: {
      zoomControl: false,
      dragging: L.Browser.mobile,
      touchZoom: true,
      maxZoom: 19,
      minZoom: 7,
      wheelPxPerZoomLevel: 600
    },
    osmLayer: null,
    googleLayer: null,
    carouselDialog: false,
    cameraDialog: false,
    carousel: null,
    camera: null,
    fotos: [],
    selFraccionId: null,
    selMejoraType: null,
    selMejoraId: null    
  }),
  mounted() {
    this.loadMap();
  },
  methods: {
    loadMap: function () {
      this.osmLayer = L.tileLayer.offline(
        "https://{s}.tile.osm.org/{z}/{x}/{y}.png",
        {
          subdomains: 'abc'
//          minZoom: 13
        }
      );
      this.googleLayer = L.tileLayer.offline(
//        "https://www.google.cn/maps/vt?lyrs=s@189&gl=cn&x={x}&y={y}&z={z}",
          "https://mt.google.com/vt/lyrs=s&x={x}&y={y}&z={z}",
        {
//          minZoom: 13
        }
      );

/*
googleSat = L.tileLayer('https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}',{
    minZoom: 13,
    maxZoom: 20,
    subdomains:['mt0','mt1','mt2','mt3']
});
*/

      this.map = L.map("map", {
        zoomControl: false,
        dragging: L.Browser.mobile,
        touchZoom: true,
        maxZoom: 18,
        minZoom: 7,
        wheelPxPerZoomLevel: 600,
        layers: [this.googleLayer]
      }).setView(this.center, this.zoom);

      this.map.pm.setLang("es");
      this.map.on('contextmenu', () => { console.log('contextmenu');});
      
      var baseMaps = {
        Google: this.googleLayer,
        OpenStreetMap: this.osmLayer
      };
      L.control.layers(baseMaps, null, { position: "topright" }).addTo(this.map);
      L.control.zoom({ position: "topright" }).addTo(this.map);
      L.control
        .locate({
          strings: {
            title: "Donde estoy?",
          },
          position: "topright",
          drawCircle: false,
          locateOptions: {
            enableHighAccuracy: true,
          },
        })
        .addTo(this.map);
      this.carousel = L.easyButton({
        position: "topright",
        states: [{
          title: 'Imagenes',
          onClick: this.showCarousel,
          icon: 'fa-image'
        }]
      });
      this.carousel.addTo(this.map);
      this.carousel.disable();

      this.camera = L.easyButton({
        position: "topright",
        states: [{
          title: 'Agregar foto',
          onClick: this.showCamera,
          icon: 'fa-camera'
        }]
      });
      this.camera.addTo(this.map);
      this.camera.disable();

      L.control.scale({ metric: true, imperial: false}).addTo(this.map);
      L.control.measure({
        position: 'topright',
        primaryLengthUnit: 'meters',
        secondaryLengthUnit: 'kilometers',
        primaryAreaUnit: 'hectares'
      }).addTo(this.map);      
    },
    showCarousel: function(){
      this.loadImages();
      if (this.fotos.length === 0){
        this.$toasted.info("La mejora no tiene fotos", {
          duration: 2000
        });
      } else {
        this.carouselDialog = true;
      }
    },
    showCamera: function(){
      let selected = this.wrkState.selected;
      let type = this.$findTypeByName(selected.type);

      this.selFraccionId = this.wrkFraccion.id;
      this.selMejoraType = type.field;
      this.selMejoraId = selected.id;    

      this.cameraDialog = true;
    },
    closeCarousel: function () {
      this.carouselDialog = false;
    },
    closeCamera: function () {
      this.cameraDialog = false;
    },
    loadImages: function(){
        this.fotos = [];
        let selected = this.wrkState.selected;
        for (let item of Object.entries(this.ModelTypes)){
          if (item[1].name === selected.type){
            let mejora = this.wrkFraccion[item[1].field].find(el => el.id === selected.id);
            mejora.fotosSIMCollection.forEach(el => {
              if (el.fotoFisica){
                this.fotos.push(el.fotoFisica);
              }
            });
            break;
          }
        }
    }
  },
  computed: {
    ...mapState(["wrkFraccion", "wrkStyleSize", "wrkState"]),
  },
  watch: {
    wrkStyleSize(newSel) {
      let size = '11px';

      switch (newSel){
        case 'normal':
          size = '12px';
          break;
        case 'big':
          size = '14px';
          break;
        case 'small':
          size = '9px';
          break;    
      }

      var bodyStyles = document.body.style;
      bodyStyles.setProperty('--measurement-font-size', size);      
    },
    wrkState(newSel){
      if (newSel.edit){        
        this.camera.enable();
      } else {
        this.camera.disable();
      }
      if (newSel.selected){
        this.carousel.enable();
      } else {
        this.carousel.disable();
      }
    }
  }
};
</script>

<style lang="css">
@import "./../../../node_modules/leaflet/dist/leaflet.css";
@import "./../../../node_modules/@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css";
@import "./../../../node_modules/leaflet.locatecontrol/dist/L.Control.Locate.min.css";
@import "./../../../node_modules/leaflet-easybutton/src/easy-button.css";
@import "./../../../node_modules/leaflet-measure/dist/leaflet-measure.css";

:root {
  --measurement-font-size: 11px;
}

.leaflet-measure-path-measurement {
  position: absolute;
  font-size: var(--measurement-font-size);
  color: black;
  text-shadow: -1px 0 5px white, -1px -1px 5px white, 0 -1px 5px white,
    1px -1px 5px white, 1px 0 5px white, 1px 1px 5px white, 0 1px 5px white,
    -1px 1px 5px white;
  white-space: nowrap;
  transform-origin: 0;
  pointer-events: none;
}

.leaflet-measure-path-measurement > div {
  position: relative;
  margin-top: -25%;
  left: -50%;
}
#map {
  height: 600px;
  width: 100%;
}
</style>