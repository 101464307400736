import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist'
import localforage from 'localforage'

import actions from './actions';
import getters from './getters';
import mutations from './mutations';
import defaultState from './state';

Vue.use(Vuex);

export default new Vuex.Store({
  state: defaultState,
  mutations,
  actions,
  getters,
  plugins: [new VuexPersistence({
    key: 'inc-sim', 
    storage: localforage,
    asyncStorage: true 
  }).plugin]
});